import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pricing13.css'

const Pricing13 = (props) => {
  const [isMonthly, setIsMonthly] = useState(true)
  return (
    <div
      className={`pricing13-pricing23 thq-section-padding ${props.rootClassName} `}
    >
      <div id="pricing" className="pricing13-max-width thq-section-max-width">
        <div className="pricing13-section-title">
          <div className="pricing13-content">
            <h2 className="pricing13-text10 thq-heading-2">
              {props.heading1 ?? (
                <Fragment>
                  <span className="pricing13-text72">
                    Simple pricing for businesses of all sizes
                  </span>
                </Fragment>
              )}
            </h2>
            <p className="pricing13-text11 thq-body-large">
              {props.content2 ?? (
                <Fragment>
                  <span className="pricing13-text80">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className="pricing13-tabs">
          {!isMonthly && (
            <button
              onClick={() => setIsMonthly(true)}
              className="pricing13-button1 thq-button-outline"
            >
              <span className="thq-body-small">Monthly</span>
            </button>
          )}
          {!isMonthly && (
            <button
              onClick={() => setIsMonthly(false)}
              className="pricing13-button2 thq-button-filled"
            >
              <span className="thq-body-small">Yearly</span>
            </button>
          )}
        </div>
        {isMonthly && (
          <div className="pricing13-container1">
            <div className="pricing13-column1 thq-card">
              <svg
                viewBox="0 0 1243.4285714285713 1024"
                className="pricing13-icon10"
              >
                <path d="M365.714 932.571l219.429-109.714v-179.429l-219.429 93.714v195.429zM329.143 673.143l230.857-98.857-230.857-98.857-230.857 98.857zM950.857 932.571l219.429-109.714v-179.429l-219.429 93.714v195.429zM914.286 673.143l230.857-98.857-230.857-98.857-230.857 98.857zM658.286 505.714l219.429-94.286v-152l-219.429 93.714v152.571zM621.714 289.143l252-108-252-108-252 108zM1243.429 585.143v237.714c0 27.429-15.429 53.143-40.571 65.143l-256 128c-10.286 5.714-21.143 8-32.571 8s-22.286-2.286-32.571-8l-256-128c-1.714-0.571-2.857-1.143-4-2.286-1.143 1.143-2.286 1.714-4 2.286l-256 128c-10.286 5.714-21.143 8-32.571 8s-22.286-2.286-32.571-8l-256-128c-25.143-12-40.571-37.714-40.571-65.143v-237.714c0-29.143 17.714-55.429 44.571-67.429l248-106.286v-228.571c0-29.143 17.714-55.429 44.571-67.429l256-109.714c9.143-4 18.857-5.714 28.571-5.714s19.429 1.714 28.571 5.714l256 109.714c26.857 12 44.571 38.286 44.571 67.429v228.571l248 106.286c27.429 12 44.571 38.286 44.571 67.429z"></path>
              </svg>
              <div className="pricing13-price1">
                <div className="pricing13-price2">
                  <p className="pricing13-text14 thq-body-large">
                    {props.plan1 ?? (
                      <Fragment>
                        <span className="pricing13-text75">Startup plan</span>
                      </Fragment>
                    )}
                  </p>
                  <h3 className="pricing13-text15 thq-heading-3">
                    {props.plan1Price ?? (
                      <Fragment>
                        <span className="pricing13-text69">$0/mo</span>
                      </Fragment>
                    )}
                  </h3>
                  <p className="pricing13-text16 thq-body-large">
                    {props.text2 ?? (
                      <Fragment>
                        <span className="pricing13-text74">
                          For entrepreneurs to experience before paid
                        </span>
                      </Fragment>
                    )}
                  </p>
                </div>
                <div className="pricing13-list1">
                  <div className="pricing13-list-item10">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pricing13-icon12 thq-icon-small"
                    >
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing13-text17 thq-body-small">
                      {props.plan1Feature1 ?? (
                        <Fragment>
                          <span className="pricing13-text52">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing13-list-item11">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pricing13-icon14 thq-icon-small"
                    >
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing13-text18 thq-body-small">
                      {props.plan1Feature2 ?? (
                        <Fragment>
                          <span className="pricing13-text51">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing13-list-item12">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pricing13-icon16 thq-icon-small"
                    >
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing13-text19 thq-body-small">
                      {props.plan1Feature3 ?? (
                        <Fragment>
                          <span className="pricing13-text77">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing13-list-item13">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pricing13-icon18 thq-icon-small"
                    >
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing13-text20 thq-body-small">
                      {props.text ?? (
                        <Fragment>
                          <span className="pricing13-text68">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <div className="pricing13-list-item14">
                  <span className="pricing13-text21 thq-body-small">
                    {props.text5 ?? (
                      <Fragment>
                        <span className="pricing13-text60">
                          Feature text goes here
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <a
                  href="https://doqqi8stylu.typeform.com/to/zDp1N8uh"
                  className="pricing13-action21 thq-button-animated thq-button-outline"
                >
                  <span className="pricing13-text22 thq-body-small">
                    {props.action2 ?? (
                      <Fragment>
                        <span className="pricing13-text49">Get a Demo</span>
                      </Fragment>
                    )}
                  </span>
                </a>
              </div>
            </div>
            <div className="pricing13-column2 thq-card">
              <div className="pricing13-price3">
                <svg
                  viewBox="0 0 1243.4285714285713 1024"
                  className="pricing13-icon20"
                >
                  <path d="M365.714 932.571l219.429-109.714v-179.429l-219.429 93.714v195.429zM329.143 673.143l230.857-98.857-230.857-98.857-230.857 98.857zM950.857 932.571l219.429-109.714v-179.429l-219.429 93.714v195.429zM914.286 673.143l230.857-98.857-230.857-98.857-230.857 98.857zM658.286 505.714l219.429-94.286v-152l-219.429 93.714v152.571zM621.714 289.143l252-108-252-108-252 108zM1243.429 585.143v237.714c0 27.429-15.429 53.143-40.571 65.143l-256 128c-10.286 5.714-21.143 8-32.571 8s-22.286-2.286-32.571-8l-256-128c-1.714-0.571-2.857-1.143-4-2.286-1.143 1.143-2.286 1.714-4 2.286l-256 128c-10.286 5.714-21.143 8-32.571 8s-22.286-2.286-32.571-8l-256-128c-25.143-12-40.571-37.714-40.571-65.143v-237.714c0-29.143 17.714-55.429 44.571-67.429l248-106.286v-228.571c0-29.143 17.714-55.429 44.571-67.429l256-109.714c9.143-4 18.857-5.714 28.571-5.714s19.429 1.714 28.571 5.714l256 109.714c26.857 12 44.571 38.286 44.571 67.429v228.571l248 106.286c27.429 12 44.571 38.286 44.571 67.429z"></path>
                </svg>
                <div className="pricing13-price4">
                  <p className="pricing13-text23 thq-body-large">
                    {props.plan2 ?? (
                      <Fragment>
                        <span className="pricing13-text73">Business plan</span>
                      </Fragment>
                    )}
                  </p>
                  <h3 className="pricing13-text24 thq-heading-3">
                    {props.plan2Price ?? (
                      <Fragment>
                        <span className="pricing13-text54">Custom</span>
                      </Fragment>
                    )}
                  </h3>
                  <p className="pricing13-text25 thq-body-large">
                    {props.text3 ?? (
                      <Fragment>
                        <span className="pricing13-text70">
                          For business to 
                        </span>
                      </Fragment>
                    )}
                  </p>
                </div>
                <div className="pricing13-list2">
                  <div className="pricing13-list-item15">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pricing13-icon22 thq-icon-small"
                    >
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing13-text26 thq-body-small">
                      {props.plan2Feature1 ?? (
                        <Fragment>
                          <span className="pricing13-text65">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing13-list-item16">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pricing13-icon24 thq-icon-small"
                    >
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing13-text27 thq-body-small">
                      {props.plan2Feature2 ?? (
                        <Fragment>
                          <span className="pricing13-text66">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing13-list-item17">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pricing13-icon26 thq-icon-small"
                    >
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing13-text28 thq-body-small">
                      {props.plan2Feature3 ?? (
                        <Fragment>
                          <span className="pricing13-text50">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing13-list-item18">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="pricing13-icon28 thq-icon-small"
                    >
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing13-text29 thq-body-small">
                      {props.text1 ?? (
                        <Fragment>
                          <span className="pricing13-text71">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <div className="pricing13-list-item19">
                  <span className="pricing13-text30 thq-body-small">
                    {props.text4 ?? (
                      <Fragment>
                        <span className="pricing13-text56">
                          Feature text goes here
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <a
                  href="https://doqqi8stylu.typeform.com/to/zDp1N8uh"
                  className="pricing13-action22 thq-button-animated thq-button-outline"
                >
                  <span className="pricing13-text31 thq-body-small">
                    {props.action2 ?? (
                      <Fragment>
                        <span className="pricing13-text49">Get a Demo</span>
                      </Fragment>
                    )}
                  </span>
                </a>
              </div>
            </div>
          </div>
        )}
        {!isMonthly && (
          <div className="pricing13-container2">
            <div className="pricing13-column3 thq-card">
              <svg
                viewBox="0 0 1243.4285714285713 1024"
                className="pricing13-icon30"
              >
                <path d="M365.714 932.571l219.429-109.714v-179.429l-219.429 93.714v195.429zM329.143 673.143l230.857-98.857-230.857-98.857-230.857 98.857zM950.857 932.571l219.429-109.714v-179.429l-219.429 93.714v195.429zM914.286 673.143l230.857-98.857-230.857-98.857-230.857 98.857zM658.286 505.714l219.429-94.286v-152l-219.429 93.714v152.571zM621.714 289.143l252-108-252-108-252 108zM1243.429 585.143v237.714c0 27.429-15.429 53.143-40.571 65.143l-256 128c-10.286 5.714-21.143 8-32.571 8s-22.286-2.286-32.571-8l-256-128c-1.714-0.571-2.857-1.143-4-2.286-1.143 1.143-2.286 1.714-4 2.286l-256 128c-10.286 5.714-21.143 8-32.571 8s-22.286-2.286-32.571-8l-256-128c-25.143-12-40.571-37.714-40.571-65.143v-237.714c0-29.143 17.714-55.429 44.571-67.429l248-106.286v-228.571c0-29.143 17.714-55.429 44.571-67.429l256-109.714c9.143-4 18.857-5.714 28.571-5.714s19.429 1.714 28.571 5.714l256 109.714c26.857 12 44.571 38.286 44.571 67.429v228.571l248 106.286c27.429 12 44.571 38.286 44.571 67.429z"></path>
              </svg>
              <div className="pricing13-price5">
                <div className="pricing13-price6">
                  <span className="pricing13-text32 thq-body-large">
                    {props.plan11 ?? (
                      <Fragment>
                        <span className="pricing13-text55">Basic plan</span>
                      </Fragment>
                    )}
                  </span>
                  <h3 className="pricing13-text33 thq-heading-3">
                    {props.plan1Price1 ?? (
                      <Fragment>
                        <span className="pricing13-text76">$200/yr</span>
                      </Fragment>
                    )}
                  </h3>
                  <span className="thq-body-large">
                    {props.plan1Yearly1 ?? (
                      <Fragment>
                        <span className="pricing13-text78">or $20 monthly</span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <div className="pricing13-list3">
                  <div className="pricing13-list-item20">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan1Feature11 ?? (
                        <Fragment>
                          <span className="pricing13-text79">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing13-list-item21">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan1Feature21 ?? (
                        <Fragment>
                          <span className="pricing13-text47">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing13-list-item22">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan1Feature31 ?? (
                        <Fragment>
                          <span className="pricing13-text62">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing13-button3 thq-button-animated thq-button-outline">
                <span className="thq-body-small">
                  {props.plan1Action1 ?? (
                    <Fragment>
                      <span className="pricing13-text58">Get started</span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
            <div className="pricing13-column4 thq-card">
              <div className="pricing13-price7">
                <svg
                  viewBox="0 0 1243.4285714285713 1024"
                  className="pricing13-icon38"
                >
                  <path d="M365.714 932.571l219.429-109.714v-179.429l-219.429 93.714v195.429zM329.143 673.143l230.857-98.857-230.857-98.857-230.857 98.857zM950.857 932.571l219.429-109.714v-179.429l-219.429 93.714v195.429zM914.286 673.143l230.857-98.857-230.857-98.857-230.857 98.857zM658.286 505.714l219.429-94.286v-152l-219.429 93.714v152.571zM621.714 289.143l252-108-252-108-252 108zM1243.429 585.143v237.714c0 27.429-15.429 53.143-40.571 65.143l-256 128c-10.286 5.714-21.143 8-32.571 8s-22.286-2.286-32.571-8l-256-128c-1.714-0.571-2.857-1.143-4-2.286-1.143 1.143-2.286 1.714-4 2.286l-256 128c-10.286 5.714-21.143 8-32.571 8s-22.286-2.286-32.571-8l-256-128c-25.143-12-40.571-37.714-40.571-65.143v-237.714c0-29.143 17.714-55.429 44.571-67.429l248-106.286v-228.571c0-29.143 17.714-55.429 44.571-67.429l256-109.714c9.143-4 18.857-5.714 28.571-5.714s19.429 1.714 28.571 5.714l256 109.714c26.857 12 44.571 38.286 44.571 67.429v228.571l248 106.286c27.429 12 44.571 38.286 44.571 67.429z"></path>
                </svg>
                <div className="pricing13-price8">
                  <span className="pricing13-text39 thq-body-large">
                    {props.plan21 ?? (
                      <Fragment>
                        <span className="pricing13-text59">Business plan</span>
                      </Fragment>
                    )}
                  </span>
                  <h3 className="pricing13-text40 thq-heading-3">
                    {props.plan2Price1 ?? (
                      <Fragment>
                        <span className="pricing13-text63">$299/yr</span>
                      </Fragment>
                    )}
                  </h3>
                  <span className="thq-body-large">
                    {props.plan2Yearly1 ?? (
                      <Fragment>
                        <span className="pricing13-text67">or $29 monthly</span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <div className="pricing13-list4">
                  <div className="pricing13-list-item23">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan2Feature11 ?? (
                        <Fragment>
                          <span className="pricing13-text48">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing13-list-item24">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan2Feature21 ?? (
                        <Fragment>
                          <span className="pricing13-text61">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing13-list-item25">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan2Feature31 ?? (
                        <Fragment>
                          <span className="pricing13-text64">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing13-list-item26">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="thq-body-small">
                      {props.plan2Feature41 ?? (
                        <Fragment>
                          <span className="pricing13-text57">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing13-button4 thq-button-filled thq-button-animated">
                <span className="thq-body-small">
                  {props.plan2Action1 ?? (
                    <Fragment>
                      <span className="pricing13-text53">Get started</span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Pricing13.defaultProps = {
  plan1Feature21: undefined,
  plan2Feature11: undefined,
  action2: undefined,
  plan2Feature3: undefined,
  plan1Feature2: undefined,
  plan1Feature1: undefined,
  plan2Action1: undefined,
  plan2Price: undefined,
  plan11: undefined,
  text4: undefined,
  plan2Feature41: undefined,
  plan1Action1: undefined,
  plan21: undefined,
  text5: undefined,
  plan2Feature21: undefined,
  plan1Feature31: undefined,
  plan2Price1: undefined,
  plan2Feature31: undefined,
  plan2Feature1: undefined,
  plan2Feature2: undefined,
  plan2Yearly1: undefined,
  text: undefined,
  rootClassName: '',
  plan1Price: undefined,
  text3: undefined,
  text1: undefined,
  heading1: undefined,
  plan2: undefined,
  text2: undefined,
  plan1: undefined,
  plan1Price1: undefined,
  plan1Feature3: undefined,
  plan1Yearly1: undefined,
  plan1Feature11: undefined,
  content2: undefined,
}

Pricing13.propTypes = {
  plan1Feature21: PropTypes.element,
  plan2Feature11: PropTypes.element,
  action2: PropTypes.element,
  plan2Feature3: PropTypes.element,
  plan1Feature2: PropTypes.element,
  plan1Feature1: PropTypes.element,
  plan2Action1: PropTypes.element,
  plan2Price: PropTypes.element,
  plan11: PropTypes.element,
  text4: PropTypes.element,
  plan2Feature41: PropTypes.element,
  plan1Action1: PropTypes.element,
  plan21: PropTypes.element,
  text5: PropTypes.element,
  plan2Feature21: PropTypes.element,
  plan1Feature31: PropTypes.element,
  plan2Price1: PropTypes.element,
  plan2Feature31: PropTypes.element,
  plan2Feature1: PropTypes.element,
  plan2Feature2: PropTypes.element,
  plan2Yearly1: PropTypes.element,
  text: PropTypes.element,
  rootClassName: PropTypes.string,
  plan1Price: PropTypes.element,
  text3: PropTypes.element,
  text1: PropTypes.element,
  heading1: PropTypes.element,
  plan2: PropTypes.element,
  text2: PropTypes.element,
  plan1: PropTypes.element,
  plan1Price1: PropTypes.element,
  plan1Feature3: PropTypes.element,
  plan1Yearly1: PropTypes.element,
  plan1Feature11: PropTypes.element,
  content2: PropTypes.element,
}

export default Pricing13
