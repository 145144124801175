import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './testimonial7.css'

const Testimonial7 = (props) => {
  return (
    <div
      id="partners"
      className={`testimonial7-container1 thq-section-padding ${props.rootClassName} `}
    >
      <h1 className="testimonial7-text10">
        {props.heading ?? (
          <Fragment>
            <span className="testimonial7-text27">
              See what users are saying
            </span>
          </Fragment>
        )}
      </h1>
      <div className="testimonial7-max-width thq-section-max-width">
        <div
          data-thq="slider"
          data-loop="true"
          data-autoplay="true"
          data-navigation="true"
          data-pagination="true"
          className="testimonial7-slider swiper"
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="testimonial7-slider-slide1 swiper-slide"
            >
              <div className="testimonial7-container2">
                <div className="testimonial7-content1">
                  <div className="testimonial7-stars1">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon10 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon12 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon14 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon16 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon18 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                  </div>
                  <p className="testimonial7-text11 thq-body-large">
                    {props.review1 ?? (
                      <Fragment>
                        <span className="testimonial7-text26">
                          Campflyer has revolutionized the marketing approach.
                          It&apos;s a powerhouse that has seamlessly optimized
                          the engagement strategies, driving unparalleled
                          results. The innovative features and user-friendly
                          interface have truly set a new standard.
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                      </Fragment>
                    )}
                  </p>
                  <div className="testimonial7-avatar1">
                    <div className="testimonial7-avatar-content1">
                      <span className="testimonial7-text12 thq-body-small">
                        {props.author1Name ?? (
                          <Fragment>
                            <span className="testimonial7-text24">
                              SKECHERS
                            </span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <img
                  alt={props.image1Alt}
                  src={props.image1Src}
                  className="testimonial7-thumbnail1 thq-img-ratio-1-1"
                />
              </div>
            </div>
            <div
              data-thq="slider-slide"
              className="testimonial7-slider-slide2 swiper-slide"
            >
              <div className="testimonial7-container3">
                <div className="testimonial7-content2">
                  <div className="testimonial7-stars2">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon20 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon22 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon24 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon26 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon28 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                  </div>
                  <p className="testimonial7-text13 thq-body-large">
                    {props.review2 ?? (
                      <Fragment>
                        <span className="testimonial7-text21">
                          <span>
                            Campflyer has been a game-changer for the luxury
                            brand&apos;s marketing efforts. Its intuitive
                            platform allows users to create targeted campaigns
                            effortlessly, delivering personalized messages
                            directly to elite clientele.
                            <span
                              dangerouslySetInnerHTML={{
                                __html: ' ',
                              }}
                            />
                          </span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </p>
                  <div className="testimonial7-avatar2">
                    <div className="testimonial7-avatar-content2">
                      <span className="testimonial7-text14 thq-body-small">
                        {props.author2Position ?? (
                          <Fragment>
                            <span className="testimonial7-text19">PANERAI</span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <img
                  alt={props.image1Alt3}
                  src={props.image1Src3}
                  className="testimonial7-thumbnail2 thq-img-ratio-1-1"
                />
              </div>
            </div>
            <div
              data-thq="slider-slide"
              className="testimonial7-slider-slide3 swiper-slide"
            >
              <div className="testimonial7-container4">
                <div className="testimonial7-content3">
                  <div className="testimonial7-stars3">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon30 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon32 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon34 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon36 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial7-icon38 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                  </div>
                  <p className="testimonial7-text15 thq-body-large">
                    {props.review21 ?? (
                      <Fragment>
                        <span className="testimonial7-text18">
                          We implemented a WhatsApp-based customer acquisition
                          approach, launching successful eCoupon campaigns that
                          drove significant online and offline traffic. The
                          platform&apos;s personalized communication features
                          have greatly enhanced customer engagement and
                          retention.
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                      </Fragment>
                    )}
                  </p>
                  <div className="testimonial7-avatar3">
                    <div className="testimonial7-avatar-content3">
                      <span className="testimonial7-text16 thq-body-small">
                        {props.author2Position1 ?? (
                          <Fragment>
                            <span className="testimonial7-text25">SASA</span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <img
                  alt={props.image1Alt32}
                  src={props.image1Src32}
                  className="testimonial7-thumbnail3 thq-img-ratio-1-1"
                />
              </div>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="testimonial7-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="testimonial7-slider-button-prev swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="testimonial7-slider-button-next swiper-button-next"
          ></div>
        </div>
      </div>
    </div>
  )
}

Testimonial7.defaultProps = {
  author1Name1: undefined,
  image1Alt3: 'Company Alt',
  review21: undefined,
  image2Src: '387062ae-8770-4724-b3f4-466020f4ea6c',
  image1Src:
    '/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(10)1-200h.png',
  author2Position: undefined,
  review11: undefined,
  review2: undefined,
  image1Src32:
    '/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(8)-300h.png',
  author1Name: undefined,
  image1Alt: 'Company Alt',
  image1Alt32: 'Company Alt',
  author2Position1: undefined,
  rootClassName: '',
  review1: undefined,
  heading: undefined,
  image1Alt1: 'Company Alt',
  image1Src1: '05584407-5327-4824-bcf4-287cc78ddc4d',
  image1Src3:
    '/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(9)1-300h.png',
}

Testimonial7.propTypes = {
  author1Name1: PropTypes.element,
  image1Alt3: PropTypes.string,
  review21: PropTypes.element,
  image2Src: PropTypes.string,
  image1Src: PropTypes.string,
  author2Position: PropTypes.element,
  review11: PropTypes.element,
  review2: PropTypes.element,
  image1Src32: PropTypes.string,
  author1Name: PropTypes.element,
  image1Alt: PropTypes.string,
  image1Alt32: PropTypes.string,
  author2Position1: PropTypes.element,
  rootClassName: PropTypes.string,
  review1: PropTypes.element,
  heading: PropTypes.element,
  image1Alt1: PropTypes.string,
  image1Src1: PropTypes.string,
  image1Src3: PropTypes.string,
}

export default Testimonial7
