import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar12 from '../components/navbar12'
import Navbar13 from '../components/navbar13'
import Hero16 from '../components/hero16'
import Hero82 from '../components/hero82'
import Features4 from '../components/features4'
import Hero81 from '../components/hero81'
import Features231 from '../components/features231'
import Features182 from '../components/features182'
import Features174 from '../components/features174'
import Features175 from '../components/features175'
import Logos11 from '../components/logos11'
import Testimonial8 from '../components/testimonial8'
import Testimonial7 from '../components/testimonial7'
import Pricing13 from '../components/pricing13'
import Team1 from '../components/team1'
import Footer31 from '../components/footer31'
import './homecn.css'

const Homecn = (props) => {
  return (
    <div className="homecn-container">
      <Helmet>
        <title>運用數據提昇線上線下營銷</title>
        <meta
          name="description"
          content="透過顧客數據平台、營銷活動自動化和全通路互動來提昇顧客增長"
        />
        <meta property="og:title" content="運用數據提昇線上線下營銷" />
        <meta
          property="og:description"
          content="透過顧客數據平台、營銷活動自動化和全通路互動來提昇顧客增長"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/92de2eef-ac29-4910-8ef3-30214074feaa/5d68eed6-af16-4d47-b340-fb3412457c87?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar12
        link1={
          <Fragment>
            <span className="homecn-text100">Why Campflyer</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="homecn-text101">Solutions</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="homecn-text102">Partners</span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="homecn-text103">錄影介紹</span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="homecn-text104">Login</span>
          </Fragment>
        }
        link31={
          <Fragment>
            <span className="homecn-text105">Pricing</span>
          </Fragment>
        }
        link32={
          <Fragment>
            <span className="homecn-text106">Pricing</span>
          </Fragment>
        }
        link43={
          <Fragment>
            <span className="homecn-text107">Terms</span>
          </Fragment>
        }
        link44={
          <Fragment>
            <span className="homecn-text108">Privacy</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="homecn-text109">預約試範</span>
          </Fragment>
        }
        link1Url="#Features23"
        link2Url="#Features174"
        link3Url="#Testimonial7"
        link4Url="#Team5"
        imageSrc1="/re%20(7)-200h.png"
        rootClassName="navbar12root-class-name4"
      ></Navbar12>
      <Navbar13
        link11="為何用 Campflyer"
        link21="方案"
        action2={
          <Fragment>
            <span className="homecn-text110">預約試範</span>
          </Fragment>
        }
        rootClassName="navbar13root-class-name1"
      ></Navbar13>
      <Hero16
        text={
          <Fragment>
            <span className="homecn-text111">Watch Video</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="homecn-text112">Get Demo</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="homecn-text113">
              Run more personalized campaigns in less time
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="homecn-text114">Grow your customers</span>
          </Fragment>
        }
        image1Src="/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(5)-700w.png"
        image2Src="/2-700w.png"
        image3Src="/5-700w.png"
        image4Src="/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(7)-700w.png"
        image5Src="/4-700w.png"
        image6Src="/3-700w.png"
        rootClassName="hero16root-class-name1"
      ></Hero16>
      <Hero82
        action1={
          <Fragment>
            <span className="homecn-text115">▷ 錄影介紹</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="homecn-text116">預約試範</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="homecn-text117">
              一站式營銷管理流程幫助客戶實現業務增長
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="homecn-text118">運用數據提昇線上線下營銷</span>
          </Fragment>
        }
        image1Src="/campflyer%20customer%20intelligence%20platform%20v3%20(11)-1400w.png"
        rootClassName="hero82root-class-name1"
      ></Hero82>
      <Features4
        sectionTitle={
          <Fragment>
            <span className="homecn-text119">傳統營銷方式流失顧客</span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="homecn-text120">匿名訪客</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="homecn-text121">數據分散</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="homecn-text122">單一活動</span>
          </Fragment>
        }
        feature4Title={
          <Fragment>
            <span className="homecn-text123">單一渠道</span>
          </Fragment>
        }
        rootClassName="features4root-class-name1"
        feature1Description={
          <Fragment>
            <span className="homecn-text124">
              無法追蹤匿名訪客的足跡限制了客戶轉換率
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="homecn-text125">
              無法運用跨渠道的數據限制了營銷活動的投放準繩度
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="homecn-text126">
              無法依個人喜好客製化內容限制了顧客對營銷活動的關注度
            </span>
          </Fragment>
        }
        feature4Description={
          <Fragment>
            <span className="homecn-text127">
              未能協調訊息和渠道限制了顧客對活動的回應率
            </span>
          </Fragment>
        }
      ></Features4>
      <Features4
        sectionTitle={
          <Fragment>
            <span className="homecn-text128">傳統營銷方式流失顧客</span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="homecn-text129">匿名訪客</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="homecn-text130">數據分散</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="homecn-text131">單一活動</span>
          </Fragment>
        }
        feature4Title={
          <Fragment>
            <span className="homecn-text132">單一渠道</span>
          </Fragment>
        }
        rootClassName="features4root-class-name2"
        feature1Description={
          <Fragment>
            <span className="homecn-text133">
              無法追蹤匿名訪客的足跡限制了客戶轉換率
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="homecn-text134">
              無法運用跨渠道的數據限制了營銷活動的投放準繩度
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="homecn-text135">
              無法依個人喜好客製化內容限制了顧客對營銷活動的關注度
            </span>
          </Fragment>
        }
        feature4Description={
          <Fragment>
            <span className="homecn-text136">
              未能協調訊息和渠道限制了顧客對活動的回應率
            </span>
          </Fragment>
        }
      ></Features4>
      <Hero81
        action2={
          <Fragment>
            <span className="homecn-text137">預約試範</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="homecn-text138">
              透過顧客數據平台、營銷活動自動化和全通路互動來提昇顧客增長
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="homecn-text139">一個平台轉化營銷數據為行動</span>
          </Fragment>
        }
        rootClassName="hero81root-class-name1"
      ></Hero81>
      <Features231
        heading1={
          <Fragment>
            <span className="homecn-text140">Our All-in-One Solution</span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="homecn-text141">Fast Setup</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="homecn-text142">Automated Run</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="homecn-text143">Personalized Reach</span>
          </Fragment>
        }
        feature4Title={
          <Fragment>
            <span className="homecn-text144">Live Feedback</span>
          </Fragment>
        }
        rootClassName="features231root-class-name1"
        feature1Description={
          <Fragment>
            <span className="homecn-text145">
              Set up campaigns without delays.
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="homecn-text146">
              Run more campaigns with less time.
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="homecn-text147">
              Customize messages based on customer engagement.
            </span>
          </Fragment>
        }
        feature4Description={
          <Fragment>
            <span className="homecn-text148">
              Optimize campaign performance with live data.
            </span>
          </Fragment>
        }
      ></Features231>
      <Features182
        feature1Title={
          <Fragment>
            <span className="homecn-text149">整合數據讓顧客旅程一目了然</span>
          </Fragment>
        }
        rootClassName="features182root-class-name2"
        feature1Slogan={
          <Fragment>
            <span className="homecn-text150">顧客數據平台 (CDP)</span>
          </Fragment>
        }
        feature1ImageSrc="/33-1400w.png"
        feature1Description={
          <Fragment>
            <span className="homecn-text151">
              顧客在消費前透過網站、社交媒體廣告、電子郵件、即時通訊軟件和其他渠道與品牌互動。顧客檔案可以幫助品牌自動生成精準及動態的目標區隔
              (target segment) ，方便品牌為每位顧客提供個人化體驗。
            </span>
          </Fragment>
        }
      ></Features182>
      <Features174
        feature1Title={
          <Fragment>
            <span className="homecn-text152">快速從構思到執行</span>
          </Fragment>
        }
        features174Id="Features174"
        rootClassName="features174root-class-name1"
        feature1Slogan={
          <Fragment>
            <span className="homecn-text153">營銷活動自動化</span>
          </Fragment>
        }
        features174Id1="Features174"
        feature1ImageSrc="/44-1400w.png"
        feature1Description={
          <Fragment>
            <span className="homecn-text154">
              運用簡單的構建組件，在短時間內設計及自動化營銷活動旅程。運用 CDP
              生成的目標區隔建立營銷策略和個人化訊息。
            </span>
          </Fragment>
        }
      ></Features174>
      <Features182
        feature1Title={
          <Fragment>
            <span className="homecn-text155">
              隨時隨地與顧客溝通
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        rootClassName="features182root-class-name3"
        feature1Slogan={
          <Fragment>
            <span className="homecn-text156">全通路互動</span>
          </Fragment>
        }
        feature1ImageSrc="/55-1400w.png"
        feature1Description={
          <Fragment>
            <span className="homecn-text157">
              在顧客旅程中跨渠道（包括電子郵件、即時通訊軟件、SMS
              簡訊等）快速傳遞連貫、迅速及互動的個人化訊息。同時優化營銷渠道組合以獲得更高的營銷預算報酬率。
            </span>
          </Fragment>
        }
      ></Features182>
      <Features175
        feature1Title={
          <Fragment>
            <span className="homecn-text158">
              Optimize campaign performance
            </span>
          </Fragment>
        }
        rootClassName="features175root-class-name1"
        feature1Slogan={
          <Fragment>
            <span className="homecn-text159">
              <span className="homecn-text160">Listen to your customers</span>
              <br></br>
            </span>
          </Fragment>
        }
        feature1ImageSrc="/campflyer%20customer%20intelligence%20platform%20%C3%A5%C2%89%C2%AF%C3%A6%C2%9C%C2%AC%20%C3%A5%C2%89%C2%AF%C3%A6%C2%9C%C2%AC-1400w.gif"
        feature1Description={
          <Fragment>
            <span className="homecn-text162">
              Maximize results for your brand with campaign performance metrics.
              Find the best strategies with A/B testing experiment and reach
              your customers with the right channels and personalized messages.
            </span>
          </Fragment>
        }
      ></Features175>
      <Logos11
        heading1={
          <Fragment>
            <span className="homecn-text163">我們支援多方平台</span>
          </Fragment>
        }
        logo1Src="/10-1400w.png"
        logo2Src="/re%20(11)-1400w.png"
        logo3Src="/re%20(12)-1400w.png"
        logo4Src="/8-1400w.png"
        logo5Src="/images%20(9)-1400w.png"
        logo6Src="/9-1400w.png"
        rootClassName="logos11root-class-name1"
      ></Logos11>
      <Testimonial8
        heading={
          <Fragment>
            <span className="homecn-text164">客戶評語</span>
          </Fragment>
        }
        review1={
          <Fragment>
            <span className="homecn-text165">
              Campflyer
              改變了營銷方式，它強大的功能優化了顧客參與策略。創新的功能和用戶友好的介面為行業樹立新的標準。
            </span>
          </Fragment>
        }
        review2={
          <Fragment>
            <span className="homecn-text166">
              <span>
                Campflyer
                改變了奢侈品牌的營銷工作。用戶友好的平台允許用戶輕鬆建立具針對性的營銷活動，直接向精英客戶傳遞個人化訊息。
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        review21={
          <Fragment>
            <span className="homecn-text169">
              我們透過建基於 WhatsApp
              上的客戶獲取方法，推出了成功的電子優惠券活動，帶來了大量的線上和線下流量。平台的個人化溝通功能大大提高了客戶參與度和保留率。
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        author1Name={
          <Fragment>
            <span className="homecn-text170">SKECHERS</span>
          </Fragment>
        }
        rootClassName="testimonial8root-class-name"
        author2Position={
          <Fragment>
            <span className="homecn-text171">PANERAI</span>
          </Fragment>
        }
        author2Position1={
          <Fragment>
            <span className="homecn-text172">SASA</span>
          </Fragment>
        }
      ></Testimonial8>
      <Testimonial7
        heading={
          <Fragment>
            <span className="homecn-text173">客戶評語</span>
          </Fragment>
        }
        review1={
          <Fragment>
            <span className="homecn-text174">
              Campflyer has revolutionized marketing approach. It&apos;s a
              powerhouse that has seamlessly optimized engagement strategies,
              driving unparalleled results. The innovative features and
              user-friendly interface have truly set a new standard.
            </span>
          </Fragment>
        }
        review2={
          <Fragment>
            <span className="homecn-text175">
              <span>
                Campflyer has been a game-changer for the luxury brand&apos;s
                marketing efforts. Its intuitive platform allows users to create
                targeted campaigns effortlessly, delivering personalized
                messages directly to elite clientele.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        review11={
          <Fragment>
            <span className="homecn-text178">
              Campflyer has revolutionized our marketing approach. It&apos;s a
              powerhouse that has seamlessly optimized our engagement
              strategies, driving unparalleled results. The innovative features
              and user-friendly interface have truly set a new standard for us.
              We extend our gratitude to your team for crafting such a
              remarkable tool that continues to exceed our expectations. Keep up
              the exceptional work!
            </span>
          </Fragment>
        }
        review21={
          <Fragment>
            <span className="homecn-text179">
              We implemented a WhatsApp-based customer acquisition approach,
              launching successful eCoupon campaigns that drove significant
              online and offline traffic. The platform&apos;s personalized
              communication features have greatly enhanced customer engagement
              and retention.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        image1Src="/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(9)-200h.png"
        author1Name={
          <Fragment>
            <span className="homecn-text180">SKECHERS</span>
          </Fragment>
        }
        author1Name1={
          <Fragment>
            <span className="homecn-text181">SKECHERS</span>
          </Fragment>
        }
        rootClassName="testimonial7root-class-name1"
        testimonial7Id="Testimonial7"
        author2Position={
          <Fragment>
            <span className="homecn-text182">PANERAI</span>
          </Fragment>
        }
        author2Position1={
          <Fragment>
            <span className="homecn-text183">SASA</span>
          </Fragment>
        }
      ></Testimonial7>
      <Pricing13
        text={
          <Fragment>
            <span className="homecn-text184">
              透過電子郵件和即時通訊軟件提供技術支援
            </span>
          </Fragment>
        }
        plan1={
          <Fragment>
            <span className="homecn-text185">初創方案</span>
          </Fragment>
        }
        plan2={
          <Fragment>
            <span className="homecn-text186">企業方案</span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="homecn-text187">
              <span>專屬的客戶經理支援</span>
              <br></br>
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="homecn-text190">付費前體驗</span>
          </Fragment>
        }
        text3={
          <Fragment>
            <span className="homecn-text191">度身定制的解決方案</span>
          </Fragment>
        }
        text4={
          <Fragment>
            <span className="homecn-text192">
              <span>*WhatsApp 和 SMS 簡訊另外收費（如適用）</span>
              <br></br>
            </span>
          </Fragment>
        }
        text5={
          <Fragment>
            <span className="homecn-text195">
              *WhatsApp 和 SMS 簡訊另外收費（如適用）
            </span>
          </Fragment>
        }
        plan11={
          <Fragment>
            <span className="homecn-text196">Basic plan</span>
          </Fragment>
        }
        plan21={
          <Fragment>
            <span className="homecn-text197">Business plan</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="homecn-text198">預約試範</span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="homecn-text199">根據您的需求度身定制</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="homecn-text200">適合各種企業規模</span>
          </Fragment>
        }
        plan1Price={
          <Fragment>
            <span className="homecn-text201">$0/月</span>
          </Fragment>
        }
        plan2Price={
          <Fragment>
            <span className="homecn-text202">定制</span>
          </Fragment>
        }
        plan1Price1={
          <Fragment>
            <span className="homecn-text203">$200/yr</span>
          </Fragment>
        }
        plan2Price1={
          <Fragment>
            <span className="homecn-text204">$299/yr</span>
          </Fragment>
        }
        plan1Action1={
          <Fragment>
            <span className="homecn-text205">Get started</span>
          </Fragment>
        }
        plan1Yearly1={
          <Fragment>
            <span className="homecn-text206">or $20 monthly</span>
          </Fragment>
        }
        plan2Action1={
          <Fragment>
            <span className="homecn-text207">Get started</span>
          </Fragment>
        }
        plan2Yearly1={
          <Fragment>
            <span className="homecn-text208">or $29 monthly</span>
          </Fragment>
        }
        plan1Feature1={
          <Fragment>
            <span className="homecn-text209">最多 100 位顧客聯絡人</span>
          </Fragment>
        }
        plan1Feature2={
          <Fragment>
            <span className="homecn-text210">1 個自動化營銷活動</span>
          </Fragment>
        }
        plan1Feature3={
          <Fragment>
            <span className="homecn-text211">
              CDP、營銷活動自動化、全通路互動
            </span>
          </Fragment>
        }
        plan2Feature1={
          <Fragment>
            <span className="homecn-text212">定制顧客聯絡人數量</span>
          </Fragment>
        }
        plan2Feature2={
          <Fragment>
            <span className="homecn-text213">
              <span>定制自動化營銷活動數量</span>
              <br></br>
            </span>
          </Fragment>
        }
        plan2Feature3={
          <Fragment>
            <span className="homecn-text216">
              CDP、營銷活動自動化、全通路互動
            </span>
          </Fragment>
        }
        rootClassName="pricing13root-class-name1"
        plan1Feature11={
          <Fragment>
            <span className="homecn-text217">Feature text goes here</span>
          </Fragment>
        }
        plan1Feature21={
          <Fragment>
            <span className="homecn-text218">Feature text goes here</span>
          </Fragment>
        }
        plan1Feature31={
          <Fragment>
            <span className="homecn-text219">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature11={
          <Fragment>
            <span className="homecn-text220">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature21={
          <Fragment>
            <span className="homecn-text221">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature31={
          <Fragment>
            <span className="homecn-text222">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature41={
          <Fragment>
            <span className="homecn-text223">Feature text goes here</span>
          </Fragment>
        }
      ></Pricing13>
      <Team1
        member1={
          <Fragment>
            <span className="homecn-text224">Jason</span>
          </Fragment>
        }
        member2={
          <Fragment>
            <span className="homecn-text225">Nathan</span>
          </Fragment>
        }
        member3={
          <Fragment>
            <span className="homecn-text226">Matt</span>
          </Fragment>
        }
        member4={
          <Fragment>
            <span className="homecn-text227">Joevent</span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="homecn-text228">
              憑藉在數據分析及程式開發的專業技能，我們設計數據解決方案幫助企業提昇CRM
              及營銷活動管理。
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="homecn-text229">重塑營銷活動管理</span>
          </Fragment>
        }
        member1Job={
          <Fragment>
            <span className="homecn-text230">技術主管</span>
          </Fragment>
        }
        member1Src="/20220130_183107-modified%20(1)-500w.png"
        member2Job={
          <Fragment>
            <span className="homecn-text231">商業主管</span>
          </Fragment>
        }
        member2Src="/master-class-five-star-review-instagram-post-1-1-modified-500w.png"
        member3Job={
          <Fragment>
            <span className="homecn-text232">工程師</span>
          </Fragment>
        }
        member3Src="/capture1-500w.jpg"
        member4Job={
          <Fragment>
            <span className="homecn-text233">工程師</span>
          </Fragment>
        }
        member4Src="/capture-500w.jpg"
        rootClassName="team1root-class-name1"
      ></Team1>
      <Footer31
        text={
          <Fragment>
            <span className="homecn-text234">
              <span>Campflyer</span>
              <br></br>
            </span>
          </Fragment>
        }
        link1={
          <Fragment>
            <span className="homecn-text237">Powered by Decoder</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="homecn-text238">
              <span>WhatsApp:</span>
              <br></br>
              <span> +852 9637 5432</span>
              <br></br>
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="homecn-text243">
              <span>Main Office:</span>
              <br></br>
              <span>
                Unit 1701, 17/F, Pan Asia Centre,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>137 Wai Yip Street, Kwun Tong, Hong Kong</span>
              <br></br>
            </span>
          </Fragment>
        }
        link11={
          <Fragment>
            <span className="homecn-text250">
              <span>
                Email:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>cs@dg-innotech.com</span>
            </span>
          </Fragment>
        }
      ></Footer31>
    </div>
  )
}

export default Homecn
