import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features182.css'

const Features182 = (props) => {
  return (
    <div
      id="solution"
      className={`features182-layout349 thq-section-padding ${props.rootClassName} `}
    >
      <div className="features182-max-width thq-section-max-width">
        <div className="features182-content1">
          <div className="features182-section-title">
            <span className="features182-text10 thq-body-small">
              {props.feature1Slogan ?? (
                <Fragment>
                  <span className="features182-text22">
                    Design and automate campaign journeys in a fraction of the
                    time
                  </span>
                </Fragment>
              )}
            </span>
            <div className="features182-content2">
              <h2 className="features182-text11 thq-heading-2">
                {props.feature1Title ?? (
                  <Fragment>
                    <span className="features182-text13">
                      Campaign Orchestration
                    </span>
                  </Fragment>
                )}
              </h2>
              <p className="features182-text12 thq-body-large">
                {props.feature1Description ?? (
                  <Fragment>
                    <span className="features182-text14">
                      <span>
                        - Flexible segmentation with 100+ filters or low code
                        SQL to reach the right targets
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        - Design dynamic user journeys with easy drag-and-drop
                        building block components
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        - Create compelling messages with visual editors and
                        ready-to-use templates
                      </span>
                    </span>
                  </Fragment>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="features182-image-container">
          <img
            alt={props.feature1ImageAlt}
            src={props.feature1ImageSrc}
            className="thq-img-ratio-16-9"
          />
        </div>
      </div>
    </div>
  )
}

Features182.defaultProps = {
  feature1Title: undefined,
  feature1Description: undefined,
  feature1ImageAlt: 'Image',
  rootClassName: '',
  feature1Slogan: undefined,
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1599514812243-d6027a99a4a3?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDc0fHxvcmFuZ2V8ZW58MHx8fHwxNzI0NjY2OTg2fDA&ixlib=rb-4.0.3&w=1400',
}

Features182.propTypes = {
  feature1Title: PropTypes.element,
  feature1Description: PropTypes.element,
  feature1ImageAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  feature1Slogan: PropTypes.element,
  feature1ImageSrc: PropTypes.string,
}

export default Features182
