import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar12 from '../components/navbar12'
import ContentList41 from '../components/content-list41'
import Footer31 from '../components/footer31'
import './privacy-policy.css'

const PrivacyPolicy = (props) => {
  return (
    <div className="privacy-policy-container">
      <Helmet>
        <title>
          Privacy-Policy - Campflyer - Empower OMO campaigns with data
        </title>
        <meta
          name="description"
          content="Enable business to engage and grow customers through personalized intelligence, smart automation and omnichannel interactions with a single data platform."
        />
        <meta
          property="og:title"
          content="Privacy-Policy - Campflyer - Empower OMO campaigns with data"
        />
        <meta
          property="og:description"
          content="Enable business to engage and grow customers through personalized intelligence, smart automation and omnichannel interactions with a single data platform."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/92de2eef-ac29-4910-8ef3-30214074feaa/5d68eed6-af16-4d47-b340-fb3412457c87?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar12
        link1={
          <Fragment>
            <span className="privacy-policy-text100">Why Campflyer</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="privacy-policy-text101">Solutions</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="privacy-policy-text102">Partners</span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="privacy-policy-text103">Login</span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="privacy-policy-text104">Login</span>
          </Fragment>
        }
        link31={
          <Fragment>
            <span className="privacy-policy-text105">Pricing</span>
          </Fragment>
        }
        link32={
          <Fragment>
            <span className="privacy-policy-text106">Pricing</span>
          </Fragment>
        }
        link43={
          <Fragment>
            <span className="privacy-policy-text107">Terms</span>
          </Fragment>
        }
        link44={
          <Fragment>
            <span className="privacy-policy-text108">Privacy</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="privacy-policy-text109">
              <span>Get a demo</span>
              <br></br>
            </span>
          </Fragment>
        }
        link1Url="#Features23"
        link2Url="#Features174"
        link3Url="#Testimonial7"
        link4Url="#Team5"
        imageSrc1="/re%20(7)-200h.png"
        rootClassName="navbar12root-class-name3"
      ></Navbar12>
      <ContentList41
        content7={
          <Fragment>
            <span className="privacy-policy-text112">
              <span>
                This privacy statement aims to provide clear, accessible, and
                easy-to-understand information to all website visitors, leads,
                customers, and the recipients of our communication services.
              </span>
              <br></br>
              <br></br>
              <span>Last updated: 14 October, 2024</span>
              <br></br>
              <br></br>
              <span>
                DG Innotech Limited, (hereinafter “we”, “us” or “our”) respects
                personal data (the “Personal Data”) of individuals. We set forth
                in this privacy policy (the “Policy”) how we or companies
                commissioned by us (data processors) collect, use, process,
                store and share the Personal Data that visitors of our website,
                our clients or their respective customers (collectively as the
                “Clients” or “you” or “your” or “yours”) provide to us, or that
                we gather from any use of our website (https://campflyer.io),
                software applications, chatbots, mobile applications, or any
                other products and services provided by us (the “Products and
                Services”).
              </span>
              <br className="privacy-policy-text120"></br>
              <br></br>
              <span>
                In line with relevant data protection laws, including but not
                limited to the Hong Kong Personal Data (Privacy) Ordinance (Cap.
                486) (“PDPO“), the European General Data Protection Regulation
                (“GDPR“), and other applicable national data protection laws
                (collectively known as “Applicable Laws“), we collect, use,
                process, store, and transfer the Personal Data of our Clients.
              </span>
              <br className="privacy-policy-text123"></br>
              <br></br>
              <span>
                Personal Data means any information that relates to an
                identified or identifiable living individual. Different pieces
                of information, which collectively identify a particular person
                also constitute personal data.
              </span>
              <br className="privacy-policy-text126"></br>
              <br></br>
              <span>
                You will be asked to consent to the terms of this Policy when
                making an enquiry, registering for events or interacting with us
                via Products and Services. To the extent permitted by Applicable
                Law, your continued use of our Products and Services constitutes
                your consent to the Policy.
              </span>
              <br className="privacy-policy-text129"></br>
              <br></br>
              <span>
                If you do not agree to this Policy, please do not provide any
                Personal Data when requested and should refrain from using our
                Products and Services.
              </span>
              <br className="privacy-policy-text132"></br>
              <br></br>
              <span className="privacy-policy-text134">
                A. LEGAL BASIS FOR PROCESSING PERSONAL DATA
              </span>
              <br></br>
              <span>
                We process Personal Data if one of the following legal basis
                applies:
              </span>
              <br></br>
              <br></br>
              <span>
                1. The Client’s consent: We process Personal Data if the Client
                has given us explicit consent to use their Personal Data. Your
                consent can be withdrawn at any time.
              </span>
              <br className="privacy-policy-text140"></br>
              <span>
                2. Contractual obligations: We process Personal Data in order to
                fulfil our contractual obligations under contracts we entered
                into with you.
              </span>
              <br className="privacy-policy-text142"></br>
              <span>
                3. For a legitimate interest: We process Personal Data where we
                believe there is a legitimate reason to protect your interests
                or the interests of a third party.
              </span>
              <br className="privacy-policy-text144"></br>
              <span>
                4. Under a legal obligation: We process Personal Data where it
                is necessary to do so in compliance with any legal obligations,
                such as for the cooperation with law enforcement or regulatory
                authority, or the exercising of our legal rights.
              </span>
              <br className="privacy-policy-text146"></br>
              <br></br>
              <span className="privacy-policy-text148">
                B. COLLECTION OF PERSONAL INFORMATION
              </span>
              <br></br>
              <span>
                Depending on the type of Products and Services that are used, we
                may collect the following types of information through Products
                and Services:
              </span>
              <br className="privacy-policy-text151"></br>
              <br className="privacy-policy-text152"></br>
              <span>
                1. Identification information – we will collect identification
                information from Clients to process any of the Client’s requests
                and instructions as well as to deliver our Products and
                Services, including but not limited to name, address, email
                address and contact information, membership ID numbers or other
                royalty membership schemes, profile or passwords, etc.
              </span>
              <br className="privacy-policy-text154"></br>
              <span>
                2. Data collected for Products and Services – we will collect
                information that is necessary for us to fulfil your requests and
                instructions.
              </span>
              <br className="privacy-policy-text156"></br>
              <span>
                3. Data concerning connection and devices – we will collect
                information automatically when you access or use our Products
                and Services, including but not limited to geo-location data,
                device id, IP address, web pages viewed, browsing time,
                click-activities, redirections, pages you visited before
                entering into our websites, etc.
              </span>
              <br className="privacy-policy-text158"></br>
              <span>
                4. Data collected by Cookies or other tracking technologies – we
                and our service providers will collect information by Cookies or
                other tracking technologies to allow us to better understand
                your access and use of our Products and Services. Please see
                Section D for more information.
              </span>
              <br className="privacy-policy-text160"></br>
              <span>
                5. Data relating to your purchase activities, including but not
                limited to purchase history, delivery histories, payment
                methods, particulars of the payment tools, etc.
              </span>
              <br className="privacy-policy-text162"></br>
              <span>
                6. Data you voluntarily provided to us, including but not
                limited to your reviews, feedback, opinions or other
                interactions you have with us about our Products and Services.
              </span>
              <br className="privacy-policy-text164"></br>
              <span>
                7. Data for detection, investigation or prevention of fraud,
                misuse of our Products and Services or other violations of any
                applicable laws
              </span>
              <br className="privacy-policy-text166"></br>
              <br className="privacy-policy-text167"></br>
              <span>
                We also collect other Personal Data provided to us as part of
                the Products and Services or for specific purposes made known to
                you at the time of collection.
              </span>
              <br className="privacy-policy-text169"></br>
              <br className="privacy-policy-text170"></br>
              <span>
                Data may also be provided to us by third parties who we assume
                have obtained your consent or otherwise are permitted by
                Applicable Laws to share such data with us.
              </span>
              <br className="privacy-policy-text172"></br>
              <br className="privacy-policy-text173"></br>
              <span>
                It is worth noting that some of the above information and data
                we collect are for processing for and on behalf of the Client
                for their purposes.
              </span>
              <br className="privacy-policy-text175"></br>
              <br></br>
              <span className="privacy-policy-text177">
                C. USE OF PERSONAL DATA
              </span>
              <br></br>
              <span>
                Depending on the type of Products and Services that are used, we
                may collect, use and process Personal Data for the following
                purposes:
              </span>
              <br></br>
              <br></br>
              <span>
                1. to provide you with and maintain our Products and Services,
                including but not limited to our software applications;
              </span>
              <br className="privacy-policy-text183"></br>
              <span>
                2. to evaluate, customise and improve the Products and Services
                and the user experience;
              </span>
              <br className="privacy-policy-text185"></br>
              <span>
                3. to provide our Clients with customer and/or technical
                support;
              </span>
              <br className="privacy-policy-text187"></br>
              <span>
                4. to analyse the use and trends of the Products and Services;
              </span>
              <br className="privacy-policy-text189"></br>
              <span>
                5. to customize the Products and Services, in particular by
                providing features or ads which correspond to the interests
                and/or preferences of Clients;
              </span>
              <br className="privacy-policy-text191"></br>
              <span>
                6. to manage any abusive, unlawful or dishonest use of our
                Products and Services or violation of our terms and conditions
                or any applicable laws;
              </span>
              <br className="privacy-policy-text193"></br>
              <span>
                7. to prepare and offer push ads, promotions, rewards, or other
                types of content or features that match your interests and
                preferences;
              </span>
              <br className="privacy-policy-text195"></br>
              <span>
                8. to improve the safety and reliability of our Products and
                Services;
              </span>
              <br className="privacy-policy-text197"></br>
              <span>
                9. to comply with our legal obligations under applicable laws;
              </span>
              <br className="privacy-policy-text199"></br>
              <span>10. to research and develop other services; or</span>
              <br className="privacy-policy-text201"></br>
              <span>
                11. any other purposes described in this Policy or for specific
                purposes made known to you at the time of collection.
              </span>
              <br></br>
              <br></br>
              <span>
                In addition, with the Client’s express consent, we will also use
                Personal Data collected by us for direct marketing and
                promotional purposes. Please see our Section E on Direct
                Marketing.
              </span>
              <br className="privacy-policy-text206"></br>
              <br></br>
              <span className="privacy-policy-text208">D. COOKIES</span>
              <br></br>
              <span>
                We use analytic technologies, such as cookies, or other
                automated tracking and technologies provided by third parties to
                collect information through automated means in order to
                understand the usage of the Products and Services.
              </span>
              <br className="privacy-policy-text211"></br>
              <br></br>
              <span>
                Cookies, or similar technologies (such cookies or other similar
                technologies, “Cookies”), are small computer files stored on
                your devices used to access our Products and Services.
              </span>
              <br className="privacy-policy-text214"></br>
              <br></br>
              <span>
                You can choose to allow the installation of these Cookies or
                choose to disable them later. You can consent or reject, or
                request to be alerted when cookies are stored by adjusting your
                web browser settings (for websites) or device settings (for
                software applications), or through third-party cookie providers.
                However, should you refuse the installation of Cookies, the
                Products and Services, along with some features, might not
                function as expected.
              </span>
              <br className="privacy-policy-text217"></br>
              <br></br>
              <span>Broadly speaking, we use cookies to:</span>
              <br className="privacy-policy-text220"></br>
              <br className="privacy-policy-text221"></br>
              <span>1. distinguish users of our Products and Services;</span>
              <br className="privacy-policy-text223"></br>
              <span>
                2. collect data about Clients’ interests, behaviours of viewing
                and using our Products and Services to improve our Products and
                Services; and
              </span>
              <br className="privacy-policy-text225"></br>
              <span>
                3. optimizing our marketing and advertising campaigns and those
                of our business partners.
              </span>
              <br className="privacy-policy-text227"></br>
              <br></br>
              <span className="privacy-policy-text229">
                E. DIRECT MARKETING
              </span>
              <br></br>
              <span>
                Only with the explicit consent of our Clients, shall we use a
                Client’s name, email address and mobile phone number to send
                direct marketing materials including news, offers, promotions,
                and joint marketing offers that we consider may be of interest
                to you. If any Client prefers not to receive any direct
                marketing communications, they may withdraw any consent given
                without charge at any time by contacting us.
              </span>
              <br className="privacy-policy-text232"></br>
              <br></br>
              <span>
                Even if you opt out of direct marketing, we may still send you
                other non-marketing communications as necessary for us to
                provide our Products and Services to you.
              </span>
              <br className="privacy-policy-text235"></br>
              <br></br>
              <span className="privacy-policy-text237">
                F. RETENTION OF PERSONAL DATA
              </span>
              <br></br>
              <span>
                We keep Personal Data for a period not exceeding what is
                required for the purposes for which the Personal Data is
                collected and in accordance with the Applicable Laws (“Retention
                Period”).
              </span>
              <br className="privacy-policy-text240"></br>
              <br></br>
              <span>
                Without affecting the general rule above, we will retain and
                store Personal Data:
              </span>
              <br></br>
              <br></br>
              <span>
                1. when collected for our Clients, for a duration until our
                Clients instruct us to destroy or return such Personal Data or
                for a period of time as agreed between us and our Clients under
                separate agreements;
              </span>
              <br className="privacy-policy-text246"></br>
              <span>
                2. for the time as long as necessary to provide the Products and
                Services;
              </span>
              <br className="privacy-policy-text248"></br>
              <span>
                3. for the duration necessary to process and handle the requests
                of our Clients;
              </span>
              <br className="privacy-policy-text250"></br>
              <span>
                4. for up to three (3) months after the Client’s contract has
                ended; or
              </span>
              <br className="privacy-policy-text252"></br>
              <span>
                5. for the time needed for compliance with applicable laws.
              </span>
              <br className="privacy-policy-text254"></br>
              <br></br>
              <span>
                When the Retention Period ends or at the request of the Client,
                we will delete the Personal Data or otherwise anonymise the
                Personal Data to a point where it is impossible to reidentify an
                individual. The Retention Period may be changed upon the
                cancellation of or re-enrollment in the Products and Services,
                or in accordance with Applicable Laws.
              </span>
              <br className="privacy-policy-text257"></br>
              <br></br>
              <span className="privacy-policy-text259">
                G. SHARING AND TRANSFER OF PERSONAL DATA
              </span>
              <br></br>
              <span>
                We share information collected as outlined below and where
                Clients have asked us to do so or otherwise consented, on a
                need-to-know basis:
              </span>
              <br className="privacy-policy-text262"></br>
              <br className="privacy-policy-text263"></br>
              <span>
                1. DG Innotech affiliates: Personal Data collected may be
                accessed by or shared with our affiliates which is located in
                Hong Kong.
              </span>
              <br className="privacy-policy-text265"></br>
              <span>
                2. Service providers: We will share Personal Data collected with
                third-party service providers or sub-processors who process such
                data for us for purposes set out in Section C or for purposes of
                our Clients. This includes: i) 
              </span>
              <span>
                cloud services providers: GCP Cloud and/or MongoDB Cloud located
                in Singapore and Hong Kong, which may not be located where the
                Clients are located; ii) 
              </span>
              <span>
                hosting services provider: GCP Cloud located in Singapore and
                Hong Kong; MongoDB Atlas located in Singapore and Hong Kong ;
                iii) 
              </span>
              <span>
                legal, accounting and other professional services providers
                located in Hong Kong; iv) 
              </span>
              <span>
                payment gateway or payment processing services providers located
                in the United States and Hong Kong; and v) 
              </span>
              <span>
                customer relationship maintenance services providers located in
                the United States.
              </span>
              <br></br>
              <span>3. government or law enforcement authorities; and/or</span>
              <br className="privacy-policy-text274"></br>
              <span>4. our assignees or successors.</span>
              <br className="privacy-policy-text276"></br>
              <br></br>
              <span>
                When we transfer the Personal Data, we will protect the Personal
                Data as described in this Policy and comply with applicable
                legal requirements for providing adequate protection for the
                transfer of Personal Data.
              </span>
              <br className="privacy-policy-text279"></br>
              <br></br>
              <span>
                We have a data processing addendum with our trusted partners
                that are incorporated in our service agreements with our data
                processing and storage partners. Our support, developers and
                other team members are located in different parts of the world,
                and they may have access to Personal Data from outside of where
                the Client lives in order to provide timely support to
                constantly maintain our Products and Services.
              </span>
              <br className="privacy-policy-text282"></br>
              <br></br>
              <span>
                By using the Products and Services, you agree that Personal Data
                may be processed by such subcontractors in countries that may
                not offer the same degree of data protection as the country
                where the Client lives. We will, however, only transfer the
                Personal Data in compliance with Applicable Laws.
              </span>
              <br className="privacy-policy-text285"></br>
              <br></br>
              <span>
                We have taken reasonable contractual measures to ensure that our
                subcontractors would process Personal Data in accordance with
                our directions and for our purposes as set forth in this Policy.
                Our subcontractors are prohibited from using Personal Data for
                any other purposes without the Data Subject’s consent.
              </span>
              <br className="privacy-policy-text288"></br>
              <br></br>
              <span className="privacy-policy-text290">
                H. SECURITY OF PERSONAL DATA
              </span>
              <br></br>
              <span>
                We take different measures to secure Personal Data. We use
                appropriate organizational and technical means to protect
                Personal Data against any loss, destruction, alteration, or
                unauthorized, unlawful or abusive use of Personal Data. We
                placed the same obligations on our subcontractors who process
                Personal Data for our purposes and on our instructions.
              </span>
              <br className="privacy-policy-text293"></br>
              <br></br>
              <span>
                Personal Data is encrypted in the database while at rest and in
                transit. We strictly restrict the scope of personnel who can
                access Personal Data and information, and require them to comply
                with their contractual confidentiality obligations. In the event
                of a data breach or other security incident of Personal Data, we
                will activate an emergency response plan to prevent the
                expansion of such security incidents and notify you in the form
                of push notifications, public announcements, etc.
              </span>
              <br className="privacy-policy-text296"></br>
              <br></br>
              <span>
                To the extent permitted by Applicable Laws, we shall not be held
                liable for any direct or indirect loss, damages, or costs
                sustained or incurred by the Data Subject in the event of a data
                breach, or if the Personal Data is subject to unauthorized use,
                access, deletion, or alteration.
              </span>
              <br className="privacy-policy-text299"></br>
              <br></br>
              <span className="privacy-policy-text301">
                I. DATA SUBJECT RIGHTS
              </span>
              <br></br>
              <span>
                The Client enjoys the following rights under the Policy:
              </span>
              <br className="privacy-policy-text304"></br>
              <br></br>
              <span className="privacy-policy-text306">
                The right to be informed
              </span>
              <br></br>
              <span>
                This Policy outlined the types of information we collect, the
                purposes of processing Personal Data and the legal basis for
                doing so as well as the transfer, storage and handling by us of
                your Personal Data.
              </span>
              <br className="privacy-policy-text309"></br>
              <br></br>
              <span className="privacy-policy-text311">
                The right of access
              </span>
              <br></br>
              <span>
                Clients are entitled to access Personal Data that is in our
                possession or under our control. The right of access includes
                the right to know how we use Personal Data in practice and with
                whom we share such information.
              </span>
              <br className="privacy-policy-text314"></br>
              <br></br>
              <span className="privacy-policy-text316">
                The right of rectification
              </span>
              <br></br>
              <span>
                Clients may contact us to correct any of the Personal Data that
                is in our possession or under our control.
              </span>
              <br className="privacy-policy-text319"></br>
              <br></br>
              <span className="privacy-policy-text321">
                The right to erasure and the right to restrict processing to
                storage
              </span>
              <br></br>
              <span>
                Clients may ask us to remove or delete Personal Data or to
                restrict the processing of Personal Data where there are no
                valid reasons for any continued processing. However, the right
                to erasure is subject to our lawful rights under Applicable Laws
                to retain Personal Data for our legitimate purposes, such as the
                enforcement of our legal rights.
              </span>
              <br className="privacy-policy-text324"></br>
              <br></br>
              <span>
                If a Client requires us to restrict the processing of Personal
                Data other than for storage purposes, we may use such Personal
                Data again if there are valid reasons under Applicable Laws for
                us to do so.
              </span>
              <br className="privacy-policy-text327"></br>
              <br></br>
              <span className="privacy-policy-text329">
                The right to data portability
              </span>
              <br></br>
              <span>
                The Client has the right to receive a copy of the Personal Data
                we process. The Client may request us to transfer it to another
                party, except under certain circumstances.
              </span>
              <br className="privacy-policy-text332"></br>
              <br></br>
              <span className="privacy-policy-text334">
                The right to object
              </span>
              <br></br>
              <span>
                The Client has the right to object to our processing or use of
                Personal Data at any time. In particular, Data Subjects can
                object to our use of Personal Data for direct marketing
                purposes.
              </span>
              <br className="privacy-policy-text337"></br>
              <br className="privacy-policy-text338"></br>
              <span className="privacy-policy-text339">
                The right to be notified of breaches
              </span>
              <br></br>
              <span>
                We shall notify the Client and any applicable regulators in
                writing in the event of a suspected or actual breach of the
                Personal Data.
              </span>
              <br className="privacy-policy-text342"></br>
              <br></br>
              <span className="privacy-policy-text344">J. CHILDREN</span>
              <br></br>
              <span>
                Our Products and Services are not directed at people under the
                age of 18 (“Children”). We do not knowingly collect, solicit,
                market or process Personal Data belonging to Children.
              </span>
              <br className="privacy-policy-text347"></br>
              <br></br>
              <span className="privacy-policy-text349">
                K. PROCESSING FOR CLIENTS
              </span>
              <br></br>
              <span>
                You acknowledge and understand that some of our Clients will
                regularly disclose to us Personal Data collected by such Clients
                in the course of accessing and using our Products and Services.
                Where applicable, the Clients agree to grant us a non-exclusive
                right to process such Personal Data as a data processor solely
                to provide our Products and Services.
              </span>
              <br className="privacy-policy-text352"></br>
              <br></br>
              <span>
                In the scenarios where we are processing Personal Data as a data
                processor for our Client’s purposes, we shall:
              </span>
              <br></br>
              <br></br>
              <span>
                1. process, use or disclose Personal Data as necessary for the
                performance of our contractual obligations owed to our Clients
                or otherwise under Clients’ consent;
              </span>
              <br className="privacy-policy-text358"></br>
              <span>
                2. make no independent use of such data whatsoever for its own
                purposes;
              </span>
              <br className="privacy-policy-text360"></br>
              <span>
                3. act at all times only in accordance with the Client’s
                instructions and requirements with respect to such Personal
                Data;
              </span>
              <br className="privacy-policy-text362"></br>
              <span>
                4. ensure that we have in place reasonably appropriate technical
                and organizational measures to protect against unauthorized or
                unlawful processing of Personal Data and against accidental loss
                or destruction of, or damage to, Personal Data;
              </span>
              <br className="privacy-policy-text364"></br>
              <span>
                5. ensure that all personnel who have access to and/or process
                Personal Data are obliged to keep such data confidential;
              </span>
              <br className="privacy-policy-text366"></br>
              <span>
                6. notify the Clients without undue delay on becoming aware of a
                personal data breach affecting Personal Data;
              </span>
              <br className="privacy-policy-text368"></br>
              <span>
                7. on the Client’s written direction, delete or return Personal
                Data and copies thereof to the Client; and
              </span>
              <br className="privacy-policy-text370"></br>
              <span>
                8. maintain, complete and accurate records and information to
                demonstrate our compliance with this clause.
              </span>
              <br className="privacy-policy-text372"></br>
              <br></br>
              <span className="privacy-policy-text374">
                L. THIRD-PARTY LINKS
              </span>
              <br></br>
              <span>
                Our Products and Services may include links to third-party
                websites which are not governed by this Policy. We do not
                collect or access data that may have been collected by these
                third parties at their discretion.
              </span>
              <br className="privacy-policy-text377"></br>
              <br></br>
              <span>
                The inclusion of third-party links does not mean that we
                approve, authorize or otherwise are affiliated with the websites
                to which the links connect. Clients are advised to remove the
                privacy notice of such third-party websites before providing any
                Personal Data to such websites or using the services of such
                websites.
              </span>
              <br></br>
              <br></br>
              <span>
                We are not responsible for the use of Personal Data by such
                third parties and we cannot guarantee that they have the same
                level of data protection policy as us.
              </span>
              <br className="privacy-policy-text383"></br>
              <br className="privacy-policy-text384"></br>
              <span className="privacy-policy-text385">
                M. CONDITIONS OF USE OF OUR PRODUCTS AND SERVICES
              </span>
              <br></br>
              <span>
                The terms of this Policy are incorporated into our Terms of Use
                which Data Subjects may refer to at the following link:
                https://campflyer.io/terms
              </span>
              <br className="privacy-policy-text388"></br>
              <br></br>
              <span className="privacy-policy-text390">
                N. CHANGES TO THE POLICY
              </span>
              <br></br>
              <span>
                We reserve the right to change the Policy at any time. If we
                change the Policy, we will communicate the changes to you
                through notifications or alerts provided to you under the
                Products and Services, or any other appropriate means, for
                example, by email, so that the Client will be fully informed of
                such changes. Any changes to the Policy will take effect thirty
                (30) days after such changes have been communicated to you. Your
                continued use of our Products and Services will be deemed as an
                acceptance of the changes to the Policy.
              </span>
              <br className="privacy-policy-text393"></br>
              <br></br>
              <span className="privacy-policy-text395">O. SEVERANCE</span>
              <br></br>
              <span>
                If any part or provision of this Policy is prohibited or deemed
                to be void or unenforceable, that part or provision shall be
                ineffective to the extent of such prohibition or
                unenforceability without invalidating the remaining provisions
                hereof.
              </span>
              <br className="privacy-policy-text398"></br>
              <br></br>
              <span className="privacy-policy-text400">P. GOVERNING LAW</span>
              <br></br>
              <span>
                This Policy shall be governed by, and construed in accordance
                with, the laws of the Hong Kong Special Administrative Region
                (“Hong Kong”). Any dispute, controversy, difference or claim
                arising out of or relating to this Policy, including the
                existence, validity, interpretation, performance, breach or
                termination thereof or any dispute regarding non-contractual
                obligations arising out of or relating to it shall be referred
                to and finally resolved by arbitration administered by the Hong
                Kong International Arbitration Centre (“HKIAC”) under the HKIAC
                Administered Arbitration Rules in force when the Notice of
                Arbitration is submitted. The seat of arbitration shall be Hong
                Kong. This arbitration clause shall be governed by Hong Kong
                law. The number of arbitrations shall be one. The arbitration
                proceedings shall be conducted in English.
              </span>
              <br className="privacy-policy-text403"></br>
              <br className="privacy-policy-text404"></br>
              <span className="privacy-policy-text405">
                Q. COMMUNICATE WITH US
              </span>
              <br></br>
              <span>
                If you have any questions or concerns regarding this Policy or
                to exercise your rights under this Policy, please contact us at
                cs@dg-innotech.com
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        content9={
          <Fragment>
            <span className="privacy-policy-text409">
              We and our affiliates offer a wide variety of products and
              services that require additional terms and conditions. Your use of
              those products and services provided by us or our affiliates are
              pursuant to a separate agreement (“General Terms Agreement”). The
              General Terms Agreement forms part of your agreement with us or
              our affiliates (as applicable). If these Terms of Use are
              inconsistent with the General Terms Agreement with respect to
              those products and services, the General Terms Agreement will
              control.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        heading7={
          <Fragment>
            <span className="privacy-policy-text410">Privacy Statement</span>
          </Fragment>
        }
      ></ContentList41>
      <Footer31
        text={
          <Fragment>
            <span className="privacy-policy-text411">
              <span>Campflyer</span>
              <br></br>
            </span>
          </Fragment>
        }
        link1={
          <Fragment>
            <span className="privacy-policy-text414">Powered by Decoder</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="privacy-policy-text415">
              <span>WhatsApp:</span>
              <br></br>
              <span> +852 9637 5432</span>
              <br></br>
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="privacy-policy-text420">
              <span>Main Office:</span>
              <br></br>
              <span>
                Unit 1701, 17/F, Pan Asia Centre,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>137 Wai Yip Street, Kwun Tong, Hong Kong</span>
              <br></br>
            </span>
          </Fragment>
        }
        link11={
          <Fragment>
            <span className="privacy-policy-text427">
              <span>
                Email:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>cs@dg-innotech.com</span>
            </span>
          </Fragment>
        }
      ></Footer31>
    </div>
  )
}

export default PrivacyPolicy
