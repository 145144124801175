import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar12 from '../components/navbar12'
import Hero16 from '../components/hero16'
import Hero82 from '../components/hero82'
import Features4 from '../components/features4'
import Hero81 from '../components/hero81'
import Features231 from '../components/features231'
import Features182 from '../components/features182'
import Features174 from '../components/features174'
import Features175 from '../components/features175'
import Logos11 from '../components/logos11'
import Testimonial7 from '../components/testimonial7'
import Pricing13 from '../components/pricing13'
import Team1 from '../components/team1'
import Footer31 from '../components/footer31'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Empower OMO campaigns with data</title>
        <meta
          name="description"
          content="Enable business to drive customer growth through personalized intelligence, smart automation and omnichannel interactions"
        />
        <meta property="og:title" content="Empower OMO campaigns with data" />
        <meta
          property="og:description"
          content="Enable business to drive customer growth through personalized intelligence, smart automation and omnichannel interactions"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/92de2eef-ac29-4910-8ef3-30214074feaa/5d68eed6-af16-4d47-b340-fb3412457c87?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar12
        link1={
          <Fragment>
            <span className="home-text100">Why Campflyer</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text101">Solutions</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text102">Partners</span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="home-text103">Login</span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="home-text104">Login</span>
          </Fragment>
        }
        link31={
          <Fragment>
            <span className="home-text105">Pricing</span>
          </Fragment>
        }
        link32={
          <Fragment>
            <span className="home-text106">Pricing</span>
          </Fragment>
        }
        link43={
          <Fragment>
            <span className="home-text107">Terms</span>
          </Fragment>
        }
        link44={
          <Fragment>
            <span className="home-text108">Privacy</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text109">
              <span>Get a demo</span>
              <br></br>
            </span>
          </Fragment>
        }
        link1Url="#Features23"
        link2Url="#Features174"
        link3Url="#Testimonial7"
        link4Url="#Team5"
        imageSrc1="/re%20(7)-200h.png"
        rootClassName="navbar12root-class-name"
      ></Navbar12>
      <Hero16
        text={
          <Fragment>
            <span className="home-text112">Watch Video</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text113">Get Demo</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text114">
              Run more personalized campaigns in less time
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text115">Grow your customers</span>
          </Fragment>
        }
        image1Src="/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(5)-700w.png"
        image2Src="/2-700w.png"
        image3Src="/5-700w.png"
        image4Src="/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(7)-700w.png"
        image5Src="/4-700w.png"
        image6Src="/3-700w.png"
        rootClassName="hero16root-class-name"
      ></Hero16>
      <Hero82
        action1={
          <Fragment>
            <span className="home-text116">▷ Watch video</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text117">Get a demo</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text118">
              One-stop campaign management platform helps customers achieve
              business growth
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text119">
              Empower OMO campaigns with data
            </span>
          </Fragment>
        }
        image1Src="/campflyer%20customer%20intelligence%20platform%20v3%20(10)-1400w.png"
        rootClassName="hero82root-class-name"
      ></Hero82>
      <Features4
        sectionTitle={
          <Fragment>
            <span className="home-text120">
              Traditional marketing tools lose customers
            </span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text121">Anonymous visitors</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text122">Dispersed data</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text123">Uniform campaigns</span>
          </Fragment>
        }
        feature4Title={
          <Fragment>
            <span className="home-text124">Single channel</span>
          </Fragment>
        }
        rootClassName="features4root-class-name"
        feature1Description={
          <Fragment>
            <span className="home-text125">
              Failing to track footprints of anonymous visitors limits customer
              conversion.
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text126">
              Failing to generate insights with data across channels limits
              marketing action.
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text127">
              Failing to tailor contents to individual preferences limits
              customer attention.
            </span>
          </Fragment>
        }
        feature4Description={
          <Fragment>
            <span className="home-text128">
              Failing to align messages and channels limits campaign
              responsiveness.
            </span>
          </Fragment>
        }
      ></Features4>
      <Hero81
        action2={
          <Fragment>
            <span className="home-text129">Explore More</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text130">
              Allows you to engage customers through personalized intelligence,
              smart automation and omnichannel interactions
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text131">
              All data and actions on single platform
            </span>
          </Fragment>
        }
        rootClassName="hero81root-class-name"
      ></Hero81>
      <Features231
        heading1={
          <Fragment>
            <span className="home-text132">Our All-in-One Solution</span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text133">Fast Setup</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text134">Automated Run</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text135">Personalized Reach</span>
          </Fragment>
        }
        feature4Title={
          <Fragment>
            <span className="home-text136">Live Feedback</span>
          </Fragment>
        }
        rootClassName="features231root-class-name"
        feature1Description={
          <Fragment>
            <span className="home-text137">
              Set up campaigns without delays.
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text138">
              Run more campaigns with less time.
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text139">
              Customize messages based on customer engagement.
            </span>
          </Fragment>
        }
        feature4Description={
          <Fragment>
            <span className="home-text140">
              Optimize campaign performance with live data.
            </span>
          </Fragment>
        }
      ></Features231>
      <Features182
        feature1Title={
          <Fragment>
            <span className="home-text141">
              Unify data to a single view of customer journey
            </span>
          </Fragment>
        }
        rootClassName="features182root-class-name1"
        feature1Slogan={
          <Fragment>
            <span className="home-text142">Customer data platform (CDP)</span>
          </Fragment>
        }
        feature1ImageSrc="/b-1400w.png"
        feature1Description={
          <Fragment>
            <span className="home-text143">
              Customers interact with brands across websites, social media ads,
              emails, chats and other channels before making a purchase. A
              single customer profile can help brands to deliver personalized
              experiences with better and dynamic target segments.
            </span>
          </Fragment>
        }
      ></Features182>
      <Features174
        feature1Title={
          <Fragment>
            <span className="home-text144">
              Move quickly from ideation to execution
            </span>
          </Fragment>
        }
        features174Id="Features174"
        rootClassName="features174root-class-name"
        feature1Slogan={
          <Fragment>
            <span className="home-text145">Marketing campaign automation</span>
          </Fragment>
        }
        features174Id1="Features174"
        feature1ImageSrc="/c-1400w.png"
        feature1Description={
          <Fragment>
            <span className="home-text146">
              Design and automate campaign journeys in a faction of time with
              easy drag-and-drop building block components. Create campaign
              strategies and personalized messages with targeted segments from
              CDP.
            </span>
          </Fragment>
        }
      ></Features174>
      <Features182
        feature1Title={
          <Fragment>
            <span className="home-text147">
              Meet customers where they are
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        rootClassName="features182root-class-name"
        feature1Slogan={
          <Fragment>
            <span className="home-text148">Cross-channel engagement</span>
          </Fragment>
        }
        feature1ImageSrc="/campflyer%20customer%20intelligence%20platform%20v3%20(9)1-1400w.png"
        feature1Description={
          <Fragment>
            <span className="home-text149">
              Deliver cohesive, responsive and interactive messages fast across
              channels including email, chats, SMS and more along the customer
              journey, while optimizing your channel mix for higher ROI.
            </span>
          </Fragment>
        }
      ></Features182>
      <Features175
        feature1Title={
          <Fragment>
            <span className="home-text150">Optimize campaign performance</span>
          </Fragment>
        }
        rootClassName="features175root-class-name"
        feature1Slogan={
          <Fragment>
            <span className="home-text151">
              <span className="home-text152">Listen to your customers</span>
              <br></br>
            </span>
          </Fragment>
        }
        feature1ImageSrc="/campflyer%20customer%20intelligence%20platform%20%C3%A5%C2%89%C2%AF%C3%A6%C2%9C%C2%AC%20%C3%A5%C2%89%C2%AF%C3%A6%C2%9C%C2%AC-1400w.gif"
        feature1Description={
          <Fragment>
            <span className="home-text154">
              Maximize results for your brand with campaign performance metrics.
              Find the best strategies with A/B testing experiment and reach
              your customers with the right channels and personalized messages.
            </span>
          </Fragment>
        }
      ></Features175>
      <Logos11
        heading1={
          <Fragment>
            <span className="home-text155">We support multiple platforms</span>
          </Fragment>
        }
        logo1Src="/10-1400w.png"
        logo2Src="/re%20(11)-1400w.png"
        logo3Src="/re%20(12)-1400w.png"
        logo4Src="/8-1400w.png"
        logo5Src="/images%20(9)-1400w.png"
        logo6Src="/9-1400w.png"
        rootClassName="logos11root-class-name"
      ></Logos11>
      <Testimonial7
        heading={
          <Fragment>
            <span className="home-text156">
              See what our partners are saying
            </span>
          </Fragment>
        }
        review1={
          <Fragment>
            <span className="home-text157">
              Campflyer has revolutionized marketing approach. It&apos;s a
              powerhouse that has seamlessly optimized engagement strategies,
              driving unparalleled results. The innovative features and
              user-friendly interface have truly set a new standard.
            </span>
          </Fragment>
        }
        review2={
          <Fragment>
            <span className="home-text158">
              <span>
                Campflyer has been a game-changer for the luxury brand&apos;s
                marketing efforts. Its intuitive platform allows users to create
                targeted campaigns effortlessly, delivering personalized
                messages directly to elite clientele.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        review11={
          <Fragment>
            <span className="home-text161">
              Campflyer has revolutionized our marketing approach. It&apos;s a
              powerhouse that has seamlessly optimized our engagement
              strategies, driving unparalleled results. The innovative features
              and user-friendly interface have truly set a new standard for us.
              We extend our gratitude to your team for crafting such a
              remarkable tool that continues to exceed our expectations. Keep up
              the exceptional work!
            </span>
          </Fragment>
        }
        review21={
          <Fragment>
            <span className="home-text162">
              We implemented a WhatsApp-based customer acquisition approach,
              launching successful eCoupon campaigns that drove significant
              online and offline traffic. The platform&apos;s personalized
              communication features have greatly enhanced customer engagement
              and retention.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        image1Src="/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(9)-200h.png"
        author1Name={
          <Fragment>
            <span className="home-text163">SKECHERS</span>
          </Fragment>
        }
        author1Name1={
          <Fragment>
            <span className="home-text164">SKECHERS</span>
          </Fragment>
        }
        rootClassName="testimonial7root-class-name"
        testimonial7Id="Testimonial7"
        author2Position={
          <Fragment>
            <span className="home-text165">PANERAI</span>
          </Fragment>
        }
        author2Position1={
          <Fragment>
            <span className="home-text166">SASA</span>
          </Fragment>
        }
      ></Testimonial7>
      <Pricing13
        text={
          <Fragment>
            <span className="home-text167">
              Customer support via emails and chats
            </span>
          </Fragment>
        }
        plan1={
          <Fragment>
            <span className="home-text168">Startup plan</span>
          </Fragment>
        }
        plan2={
          <Fragment>
            <span className="home-text169">Business plan</span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="home-text170">
              Dedicated account manager support
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="home-text171">Experience before paid</span>
          </Fragment>
        }
        text3={
          <Fragment>
            <span className="home-text172">Build tailored solutions</span>
          </Fragment>
        }
        text4={
          <Fragment>
            <span className="home-text173">
              *WhatsApp and SMS fees charged when applicable
            </span>
          </Fragment>
        }
        text5={
          <Fragment>
            <span className="home-text174">
              *WhatsApp and SMS fees charged when applicable
            </span>
          </Fragment>
        }
        plan11={
          <Fragment>
            <span className="home-text175">Basic plan</span>
          </Fragment>
        }
        plan21={
          <Fragment>
            <span className="home-text176">Business plan</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text177">Get a Demo</span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="home-text178">
              Tailored for all business sizes, with customizable options and
              scalability
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text179">
              Simple pricing for businesses of all sizes
            </span>
          </Fragment>
        }
        plan1Price={
          <Fragment>
            <span className="home-text180">$0/mo</span>
          </Fragment>
        }
        plan2Price={
          <Fragment>
            <span className="home-text181">Custom</span>
          </Fragment>
        }
        plan1Price1={
          <Fragment>
            <span className="home-text182">$200/yr</span>
          </Fragment>
        }
        plan2Price1={
          <Fragment>
            <span className="home-text183">$299/yr</span>
          </Fragment>
        }
        plan1Action1={
          <Fragment>
            <span className="home-text184">Get started</span>
          </Fragment>
        }
        plan1Yearly1={
          <Fragment>
            <span className="home-text185">or $20 monthly</span>
          </Fragment>
        }
        plan2Action1={
          <Fragment>
            <span className="home-text186">Get started</span>
          </Fragment>
        }
        plan2Yearly1={
          <Fragment>
            <span className="home-text187">or $29 monthly</span>
          </Fragment>
        }
        plan1Feature1={
          <Fragment>
            <span className="home-text188">Up to 100 client contacts</span>
          </Fragment>
        }
        plan1Feature2={
          <Fragment>
            <span className="home-text189">1 active campaign</span>
          </Fragment>
        }
        plan1Feature3={
          <Fragment>
            <span className="home-text190">CDP, Automation, Omnichannel</span>
          </Fragment>
        }
        plan2Feature1={
          <Fragment>
            <span className="home-text191">
              Custom number of client contacts 
            </span>
          </Fragment>
        }
        plan2Feature2={
          <Fragment>
            <span className="home-text192">
              Custom number of active campaigns
            </span>
          </Fragment>
        }
        plan2Feature3={
          <Fragment>
            <span className="home-text193">CDP, Automation, Omnichannel</span>
          </Fragment>
        }
        rootClassName="pricing13root-class-name"
        plan1Feature11={
          <Fragment>
            <span className="home-text194">Feature text goes here</span>
          </Fragment>
        }
        plan1Feature21={
          <Fragment>
            <span className="home-text195">Feature text goes here</span>
          </Fragment>
        }
        plan1Feature31={
          <Fragment>
            <span className="home-text196">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature11={
          <Fragment>
            <span className="home-text197">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature21={
          <Fragment>
            <span className="home-text198">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature31={
          <Fragment>
            <span className="home-text199">Feature text goes here</span>
          </Fragment>
        }
        plan2Feature41={
          <Fragment>
            <span className="home-text200">Feature text goes here</span>
          </Fragment>
        }
      ></Pricing13>
      <Team1
        member1={
          <Fragment>
            <span className="home-text201">Jason</span>
          </Fragment>
        }
        member2={
          <Fragment>
            <span className="home-text202">Nathan</span>
          </Fragment>
        }
        member3={
          <Fragment>
            <span className="home-text203">Matt</span>
          </Fragment>
        }
        member4={
          <Fragment>
            <span className="home-text204">Joevent</span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="home-text205">
              With our expertise in data analytics and software development, we
              help businesses revolutionize their CRM and campaign management
              through data solutions.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text206">Reshape campaign management</span>
          </Fragment>
        }
        member1Job={
          <Fragment>
            <span className="home-text207">Technology Lead</span>
          </Fragment>
        }
        member1Src="/20220130_183107-modified%20(1)-500w.png"
        member2Job={
          <Fragment>
            <span className="home-text208">Business Lead</span>
          </Fragment>
        }
        member2Src="/master-class-five-star-review-instagram-post-1-1-modified-500w.png"
        member3Job={
          <Fragment>
            <span className="home-text209">Engineer</span>
          </Fragment>
        }
        member3Src="/capture1-500w.jpg"
        member4Job={
          <Fragment>
            <span className="home-text210">Engineer</span>
          </Fragment>
        }
        member4Src="/capture-500w.jpg"
        rootClassName="team1root-class-name"
      ></Team1>
      <Footer31
        text={
          <Fragment>
            <span className="home-text211">
              <span>Campflyer</span>
              <br></br>
            </span>
          </Fragment>
        }
        link1={
          <Fragment>
            <span className="home-text214">Powered by Decoder</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text215">
              <span>WhatsApp:</span>
              <br></br>
              <span> +852 9637 5432</span>
              <br></br>
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text220">
              <span>Main Office:</span>
              <br></br>
              <span>
                Unit 1701, 17/F, Pan Asia Centre,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>137 Wai Yip Street, Kwun Tong, Hong Kong</span>
              <br></br>
            </span>
          </Fragment>
        }
        link11={
          <Fragment>
            <span className="home-text227">
              <span>
                Email:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>cs@dg-innotech.com</span>
            </span>
          </Fragment>
        }
      ></Footer31>
    </div>
  )
}

export default Home
