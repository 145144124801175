import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './content-list41.css'

const ContentList41 = (props) => {
  return (
    <div className="content-list41-container thq-section-padding">
      <div className="content-list41-max-width thq-flex-column thq-section-max-width">
        <div className="content-list41-content1 thq-flex-column">
          <ul className="content-list41-ul1 thq-flex-column">
            <li className="content-list41-li1 thq-flex-column list-item">
              <h2 className="content-list41-heading7 thq-heading-2">
                {props.heading7 ?? (
                  <Fragment>
                    <span className="content-list41-text2">
                      Terms of service
                    </span>
                  </Fragment>
                )}
              </h2>
              <p className="thq-body-small">
                {props.content7 ?? (
                  <Fragment>
                    <span className="content-list41-text4">
                      Lorem ipsum dolor sit amet. Vel dolores illum est aperiam
                      quis nam voluptatem quia et omnis autem qui dolore ullam
                      sed fugiat cumque! Qui accusamus assumenda et molestias
                      eius et error sunt. Id recusandae nostrum ea officiis
                      voluptatem in nisi consequatur sed quia tenetur sit alias
                      molestias qui illum soluta. Est nesciunt perferendis eum
                      sint rerum 33 cupiditate dolorem id corrupti laboriosam ut
                      debitis veniam ut ipsam fugit vel sunt consequatur. Et
                      nobis quasi et cumque adipisci aut molestiae eligendi quo
                      inventore dicta ea suscipit sequi sed veritatis nemo.
                    </span>
                  </Fragment>
                )}
              </p>
            </li>
          </ul>
        </div>
        <div className="content-list41-content2 thq-flex-column">
          <ul className="content-list41-ul2 thq-flex-column">
            <li className="content-list41-li2 thq-flex-column list-item"></li>
          </ul>
        </div>
        <div className="content-list41-content3 thq-flex-column">
          <ul className="content-list41-ul3 thq-flex-column">
            <li className="content-list41-li3 thq-flex-column list-item"></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

ContentList41.defaultProps = {
  heading14: undefined,
  heading7: undefined,
  content9: undefined,
  content7: undefined,
}

ContentList41.propTypes = {
  heading14: PropTypes.element,
  heading7: PropTypes.element,
  content9: PropTypes.element,
  content7: PropTypes.element,
}

export default ContentList41
