import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features4.css'

const Features4 = (props) => {
  return (
    <div
      id="feature"
      className={`features4-layout301 thq-section-padding ${props.rootClassName} `}
    >
      <div className="features4-max-width thq-section-max-width">
        <h2 className="features4-text10 thq-heading-2">
          {props.sectionTitle ?? (
            <Fragment>
              <span className="features4-text19">
                Discover the Key Features
              </span>
            </Fragment>
          )}
        </h2>
        <div className="features4-row thq-grid-auto-300">
          <div className="features4-feature1">
            <div className="features4-content1 thq-flex-column">
              <div className="features4-section-title1 thq-flex-column">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 48 48"
                  className="features4-icon10"
                >
                  <g fill="currentColor">
                    <path d="M32 20a8 8 0 1 1-16 0a8 8 0 0 1 16 0"></path>
                    <path
                      d="M23.184 43.984C12.517 43.556 4 34.772 4 24C4 12.954 12.954 4 24 4s20 8.954 20 20s-8.954 20-20 20h-.274q-.272 0-.542-.016M11.166 36.62a3.028 3.028 0 0 1 2.523-4.005c7.796-.863 12.874-.785 20.632.018a2.99 2.99 0 0 1 2.498 4.002A17.94 17.94 0 0 0 42 24c0-9.941-8.059-18-18-18S6 14.059 6 24c0 4.916 1.971 9.373 5.166 12.621"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
                <h3 className="features4-title1 thq-heading-3">
                  {props.feature1Title ?? (
                    <Fragment>
                      <span className="features4-text18">
                        Discover the Magic of Lorem Ipsum Text Generation
                      </span>
                    </Fragment>
                  )}
                </h3>
                <span className="features4-description1 thq-body-small">
                  {props.feature1Description ?? (
                    <Fragment>
                      <span className="features4-text15">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim in eros elementum tristique.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="features4-feature2">
            <svg
              width="50"
              height="50"
              viewBox="0 0 20 20"
              className="features4-icon14"
            >
              <path
                d="M3 6.25A3.25 3.25 0 0 1 6.25 3H9v18H6.25A3.25 3.25 0 0 1 3 17.75zM10.5 21h7.25A3.25 3.25 0 0 0 21 17.75V15.5H10.5zM21 14V6.25A3.25 3.25 0 0 0 17.75 3H10.5v11z"
                fill="currentColor"
              ></path>
            </svg>
            <div className="features4-content2 thq-flex-column">
              <div className="features4-section-title2 thq-flex-column">
                <strong className="features4-title2 thq-heading-3">
                  {props.feature2Title ?? (
                    <Fragment>
                      <span className="features4-text17">
                        Discover the Magic of Lorem Ipsum Text Generation
                      </span>
                    </Fragment>
                  )}
                </strong>
                <span className="features4-description2 thq-body-small">
                  {props.feature2Description ?? (
                    <Fragment>
                      <span className="features4-text13">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim in eros elementum tristique.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="features4-feature3">
            <svg
              width="50"
              height="50"
              viewBox="0 0 32 32"
              className="features4-icon16"
            >
              <path
                d="M26 18c-1.858 0-3.41 1.28-3.858 3H2v2h20.142c.447 1.72 2 3 3.858 3c2.206 0 4-1.794 4-4s-1.794-4-4-4m0 6c-1.103 0-2-.897-2-2s.897-2 2-2s2 .898 2 2s-.897 2-2 2m0-18c-1.858 0-3.41 1.28-3.858 3H2v2h20.142c.447 1.72 2 3 3.858 3c2.206 0 4-1.794 4-4s-1.794-4-4-4m0 6c-1.103 0-2-.897-2-2s.897-2 2-2s2 .898 2 2s-.897 2-2 2"
                fill="currentColor"
              ></path>
            </svg>
            <div className="features4-content3 thq-flex-column">
              <div className="features4-section-title3 thq-flex-column">
                <strong className="features4-title3 thq-heading-3">
                  {props.feature3Title ?? (
                    <Fragment>
                      <span className="features4-text12">
                        Discover the Magic of Lorem Ipsum Text Generation
                      </span>
                    </Fragment>
                  )}
                </strong>
                <span className="features4-description3 thq-body-small">
                  {props.feature3Description ?? (
                    <Fragment>
                      <span className="features4-text16">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim in eros elementum tristique.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="features4-feature4">
            <svg
              width="50"
              height="50"
              viewBox="0 0 24 24"
              className="features4-icon18"
            >
              <path
                d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 4l-8 5l-8-5V6l8 5l8-5z"
                fill="currentColor"
              ></path>
            </svg>
            <div className="features4-content4 thq-flex-column">
              <div className="features4-section-title4 thq-flex-column">
                <strong className="features4-title4 thq-heading-3">
                  {props.feature4Title ?? (
                    <Fragment>
                      <span className="features4-text11">
                        Discover the Magic of Lorem Ipsum Text Generation
                      </span>
                    </Fragment>
                  )}
                </strong>
                <span className="features4-description4 thq-body-small">
                  {props.feature4Description ?? (
                    <Fragment>
                      <span className="features4-text14">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim in eros elementum tristique.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Features4.defaultProps = {
  feature4Title: undefined,
  feature3Title: undefined,
  feature2Description: undefined,
  rootClassName: '',
  feature4Description: undefined,
  feature1Description: undefined,
  feature3Description: undefined,
  feature2Title: undefined,
  feature1Title: undefined,
  sectionTitle: undefined,
}

Features4.propTypes = {
  feature4Title: PropTypes.element,
  feature3Title: PropTypes.element,
  feature2Description: PropTypes.element,
  rootClassName: PropTypes.string,
  feature4Description: PropTypes.element,
  feature1Description: PropTypes.element,
  feature3Description: PropTypes.element,
  feature2Title: PropTypes.element,
  feature1Title: PropTypes.element,
  sectionTitle: PropTypes.element,
}

export default Features4
