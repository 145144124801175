import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hero16.css'

const Hero16 = (props) => {
  return (
    <div className={`thq-section-padding ${props.rootClassName} `}>
      <div className="hero16-max-width thq-section-max-width">
        <nav id="Home" className="hero16-column thq-section-padding">
          <h1 className="hero16-text10 thq-heading-1">
            {props.heading1 ?? (
              <Fragment>
                <span className="hero16-text15">
                  <span className="hero16-text16">Campaign Management</span>
                  <br></br>
                </span>
              </Fragment>
            )}
          </h1>
          <p className="hero16-text11 thq-body-large">
            {props.content1 ?? (
              <Fragment>
                <span className="hero16-text18">
                  Deliver the right messages to the right customers
                </span>
              </Fragment>
            )}
          </p>
          <div className="hero16-actions">
            <a
              href="https://doqqi8stylu.typeform.com/to/zDp1N8uh"
              target="_blank"
              rel="noreferrer noopener"
              className="hero16-button1 thq-button-filled thq-button-animated"
            >
              <span className="hero16-text12 thq-body-small">
                {props.action1 ?? (
                  <Fragment>
                    <span className="hero16-text14">Book a Demo</span>
                  </Fragment>
                )}
              </span>
            </a>
            <a
              href="#video"
              className="hero16-button2 thq-button-animated thq-button-outline"
            >
              <span className="hero16-text13">
                {props.text ?? (
                  <Fragment>
                    <span className="hero16-text19">Watch Demo</span>
                  </Fragment>
                )}
              </span>
            </a>
          </div>
        </nav>
        <div className="hero16-content">
          <div className="hero16-column-container1 thq-mask-image-vertical thq-animated-group-container-vertical">
            <div className="thq-animated-group-vertical">
              <img
                alt={props.image1Alt}
                src={props.image1Src}
                className="hero16-placeholder-image10 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt={props.image2Alt}
                src={props.image2Src}
                className="hero16-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt={props.image3Alt}
                src={props.image3Src}
                className="hero16-placeholder-image12 thq-img-scale thq-img-ratio-1-1"
              />
            </div>
            <div className="thq-animated-group-vertical">
              <img
                alt={props.image1Alt}
                src={props.image1Src}
                className="hero16-placeholder-image13 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt={props.image2Alt}
                src={props.image2Src}
                className="hero16-placeholder-image14 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt={props.image3Alt}
                src={props.image3Src}
                className="hero16-placeholder-image15 thq-img-scale thq-img-ratio-1-1"
              />
            </div>
          </div>
          <div className="hero16-column-container2 thq-mask-image-vertical thq-animated-group-container-vertical">
            <div className="thq-animated-group-vertical-reverse">
              <img
                alt={props.image4Alt}
                src={props.image4Src}
                className="hero16-placeholder-image16 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt={props.image5Alt}
                src={props.image5Src}
                className="hero16-placeholder-image17 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt={props.image6Alt}
                src={props.image6Src}
                className="hero16-placeholder-image18 thq-img-scale thq-img-ratio-1-1"
              />
            </div>
            <div className="thq-animated-group-vertical-reverse">
              <img
                alt={props.image4Alt}
                src={props.image4Src}
                className="hero16-placeholder-image19 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt={props.image5Alt}
                src={props.image5Src}
                className="hero16-placeholder-image20 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt={props.image6Alt}
                src={props.image6Src}
                className="hero16-placeholder-image21 thq-img-scale thq-img-ratio-1-1"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="hero16-container2">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

Hero16.defaultProps = {
  image1Alt: 'Hero Image',
  image4Src:
    'https://images.unsplash.com/photo-1557672172-298e090bd0f1?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEwfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&ixlib=rb-4.0.3&w=700',
  action1: undefined,
  heading1: undefined,
  image5Alt: 'Hero Image',
  rootClassName: '',
  image6Src:
    'https://images.unsplash.com/photo-1553356084-58ef4a67b2a7?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI0fHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&ixlib=rb-4.0.3&w=700',
  content1: undefined,
  image2Src:
    'https://images.unsplash.com/photo-1552083974-186346191183?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&ixlib=rb-4.0.3&w=700',
  image2Alt: 'Hero Image',
  image4Alt: 'Hero Image',
  image5Src:
    'https://images.unsplash.com/photo-1523821741446-edb2b68bb7a0?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI5fHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&ixlib=rb-4.0.3&w=700',
  image3Src:
    'https://images.unsplash.com/photo-1563089145-599997674d42?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDR8fGFic3RyYWN0fGVufDB8fHx8MTcxMDg3MDkzMHww&ixlib=rb-4.0.3&w=700',
  image6Alt: 'Hero Image',
  image3Alt: 'Hero Image',
  text: undefined,
  image1Src:
    '/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(5)-700w.png',
}

Hero16.propTypes = {
  image1Alt: PropTypes.string,
  image4Src: PropTypes.string,
  action1: PropTypes.element,
  heading1: PropTypes.element,
  image5Alt: PropTypes.string,
  rootClassName: PropTypes.string,
  image6Src: PropTypes.string,
  content1: PropTypes.element,
  image2Src: PropTypes.string,
  image2Alt: PropTypes.string,
  image4Alt: PropTypes.string,
  image5Src: PropTypes.string,
  image3Src: PropTypes.string,
  image6Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  text: PropTypes.element,
  image1Src: PropTypes.string,
}

export default Hero16
