import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './team1.css'

const Team1 = (props) => {
  return (
    <div id="team" className={`thq-section-padding ${props.rootClassName} `}>
      <div className="team1-max-width thq-grid-4 thq-section-max-width">
        <div className="team1-section-title">
          <div className="team1-content1">
            <h2 className="team1-text10 thq-heading-2">
              {props.heading1 ?? (
                <Fragment>
                  <span className="team1-text30">Meet our team</span>
                </Fragment>
              )}
            </h2>
            <p className="team1-text11 thq-body-large">
              {props.content2 ?? (
                <Fragment>
                  <span className="team1-text23">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className="team1-content2">
          <div className="team1-content3">
            <div className="team1-row">
              <div className="team1-container1">
                <div className="team1-card1">
                  <img
                    alt={props.member1Alt}
                    src={props.member1Src}
                    className="team1-placeholder-image1 thq-img-round thq-img-ratio-1-1"
                  />
                  <div className="team1-content4">
                    <div className="team1-title1">
                      <span className="team1-text12 thq-body-small">
                        {props.member1 ?? (
                          <Fragment>
                            <span className="team1-text26">Full name</span>
                          </Fragment>
                        )}
                      </span>
                      <span className="team1-text13 thq-body-small">
                        {props.member1Job ?? (
                          <Fragment>
                            <span className="team1-text31">Job title</span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="team1-social-icons1"></div>
                </div>
                <div className="team1-card2">
                  <img
                    alt={props.member2Alt}
                    src={props.member2Src}
                    className="team1-placeholder-image2 thq-img-round thq-img-ratio-1-1"
                  />
                  <div className="team1-content5">
                    <div className="team1-title2">
                      <span className="team1-text14 thq-body-small">
                        {props.member2 ?? (
                          <Fragment>
                            <span className="team1-text28">Full name</span>
                          </Fragment>
                        )}
                      </span>
                      <span className="team1-text15 thq-body-small">
                        {props.member2Job ?? (
                          <Fragment>
                            <span className="team1-text29">Job title</span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="team1-social-icons2"></div>
                </div>
              </div>
              <div className="team1-container2">
                <div className="team1-card3">
                  <img
                    alt={props.member3Alt}
                    src={props.member3Src}
                    className="team1-placeholder-image3 thq-img-round thq-img-ratio-1-1"
                  />
                  <div className="team1-content6">
                    <div className="team1-title3">
                      <span className="team1-text16 thq-body-small">
                        {props.member3 ?? (
                          <Fragment>
                            <span className="team1-text20">Full name</span>
                          </Fragment>
                        )}
                      </span>
                      <span className="team1-text17 thq-body-small">
                        {props.member3Job ?? (
                          <Fragment>
                            <span className="team1-text27">Job title</span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="team1-social-icons3"></div>
                </div>
                <div className="team1-card4">
                  <img
                    alt={props.member4Alt}
                    src={props.member4Src}
                    className="team1-placeholder-image4 thq-img-round thq-img-ratio-1-1"
                  />
                  <div className="team1-content7">
                    <div className="team1-title4">
                      <span className="team1-text18 thq-body-small">
                        {props.member4 ?? (
                          <Fragment>
                            <span className="team1-text22">Full name</span>
                          </Fragment>
                        )}
                      </span>
                      <span className="team1-text19 thq-body-small">
                        {props.member4Job ?? (
                          <Fragment>
                            <span className="team1-text21">Job title</span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="team1-social-icons4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Team1.defaultProps = {
  member3: undefined,
  member4Job: undefined,
  rootClassName: '',
  member2Alt: 'Member Alt',
  member4: undefined,
  content2: undefined,
  member1: undefined,
  member3Job: undefined,
  member1Src:
    'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDF8fHVzZXJ8ZW58MHx8fHwxNzEwOTQyNjEyfDA&ixlib=rb-4.0.3&w=500',
  member3Src:
    'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fHVzZXJ8ZW58MHx8fHwxNzEwOTQyNjEyfDA&ixlib=rb-4.0.3&w=500',
  member2: undefined,
  member2Src:
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fHVzZXJ8ZW58MHx8fHwxNzEwOTQyNjEyfDA&ixlib=rb-4.0.3&w=500',
  member2Job: undefined,
  heading1: undefined,
  member3Alt: 'Member Alt',
  member1Job: undefined,
  member1Alt: 'Member Alt',
  member4Alt: 'Member Alt',
  member4Src:
    'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDV8fHVzZXJ8ZW58MHx8fHwxNzEwOTQyNjEyfDA&ixlib=rb-4.0.3&w=500',
}

Team1.propTypes = {
  member3: PropTypes.element,
  member4Job: PropTypes.element,
  rootClassName: PropTypes.string,
  member2Alt: PropTypes.string,
  member4: PropTypes.element,
  content2: PropTypes.element,
  member1: PropTypes.element,
  member3Job: PropTypes.element,
  member1Src: PropTypes.string,
  member3Src: PropTypes.string,
  member2: PropTypes.element,
  member2Src: PropTypes.string,
  member2Job: PropTypes.element,
  heading1: PropTypes.element,
  member3Alt: PropTypes.string,
  member1Job: PropTypes.element,
  member1Alt: PropTypes.string,
  member4Alt: PropTypes.string,
  member4Src: PropTypes.string,
}

export default Team1
