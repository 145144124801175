import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './hero82.css'

const Hero82 = (props) => {
  return (
    <div
      className={`hero82-header26 thq-section-padding ${props.rootClassName} `}
    >
      <div className="hero82-max-width thq-flex-column thq-section-max-width">
        <div className="hero82-column">
          <div className="hero82-content">
            <h1 className="hero82-text1 thq-heading-1">
              {props.heading1 ?? (
                <Fragment>
                  <span className="hero82-text5">
                    Catchy and impactful hero headline
                  </span>
                </Fragment>
              )}
            </h1>
            <p className="hero82-text2 thq-body-large">
              {props.content1 ?? (
                <Fragment>
                  <span className="hero82-text7">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat.
                  </span>
                </Fragment>
              )}
            </p>
            <div className="hero82-actions">
              <a
                href="https://doqqi8stylu.typeform.com/to/zDp1N8uh"
                className="hero82-action2 thq-button-animated thq-button-outline"
              >
                <span className="hero82-text3 thq-body-small">
                  {props.action2 ?? (
                    <Fragment>
                      <span className="hero82-text8">Get a demo</span>
                    </Fragment>
                  )}
                </span>
              </a>
              <a
                href="#video"
                className="hero82-action1 thq-button-filled thq-button-animated"
              >
                <span className="hero82-text4 thq-body-small">
                  {props.action1 ?? (
                    <Fragment>
                      <span className="hero82-text6">Watch video</span>
                    </Fragment>
                  )}
                </span>
              </a>
            </div>
          </div>
        </div>
        <img
          alt={props.image1Alt}
          src={props.image1Src}
          className="thq-img-ratio-16-9"
        />
      </div>
    </div>
  )
}

Hero82.defaultProps = {
  heading1: undefined,
  action1: undefined,
  content1: undefined,
  image1Src:
    'https://images.unsplash.com/photo-1552083974-186346191183?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&ixlib=rb-4.0.3&w=1400',
  image1Alt: 'Image',
  action2: undefined,
  rootClassName: '',
}

Hero82.propTypes = {
  heading1: PropTypes.element,
  action1: PropTypes.element,
  content1: PropTypes.element,
  image1Src: PropTypes.string,
  image1Alt: PropTypes.string,
  action2: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Hero82
