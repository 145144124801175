import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar12 from '../components/navbar12'
import ContentList41 from '../components/content-list41'
import Footer31 from '../components/footer31'
import './terms-of-service.css'

const TermsOfService = (props) => {
  return (
    <div className="terms-of-service-container">
      <Helmet>
        <title>
          Terms-of-service - Campflyer - Empower OMO campaigns with data
        </title>
        <meta
          name="description"
          content="Enable business to engage and grow customers through personalized intelligence, smart automation and omnichannel interactions with a single data platform."
        />
        <meta
          property="og:title"
          content="Terms-of-service - Campflyer - Empower OMO campaigns with data"
        />
        <meta
          property="og:description"
          content="Enable business to engage and grow customers through personalized intelligence, smart automation and omnichannel interactions with a single data platform."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/92de2eef-ac29-4910-8ef3-30214074feaa/5d68eed6-af16-4d47-b340-fb3412457c87?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar12
        link1={
          <Fragment>
            <span className="terms-of-service-text100">Why Campflyer</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="terms-of-service-text101">Solutions</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="terms-of-service-text102">Partners</span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="terms-of-service-text103">Login</span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="terms-of-service-text104">Login</span>
          </Fragment>
        }
        link31={
          <Fragment>
            <span className="terms-of-service-text105">Pricing</span>
          </Fragment>
        }
        link32={
          <Fragment>
            <span className="terms-of-service-text106">Pricing</span>
          </Fragment>
        }
        link43={
          <Fragment>
            <span className="terms-of-service-text107">Terms</span>
          </Fragment>
        }
        link44={
          <Fragment>
            <span className="terms-of-service-text108">Privacy</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="terms-of-service-text109">
              <span>Get a demo</span>
              <br></br>
            </span>
          </Fragment>
        }
        link1Url="#Features23"
        link2Url="#Features174"
        link3Url="#Testimonial7"
        link4Url="#Team5"
        imageSrc1="/re%20(7)-200h.png"
        rootClassName="navbar12root-class-name2"
      ></Navbar12>
      <ContentList41
        content7={
          <Fragment>
            <span className="terms-of-service-text112">
              <span>
                Thank you for visiting our Site! We look forward to doing
                business with you. Here are some Do’s and Don’ts about our Site.
              </span>
              <br className="terms-of-service-text114"></br>
              <br></br>
              <span>Last updated: 14 October, 2024</span>
              <br className="terms-of-service-text117"></br>
              <br></br>
              <span className="terms-of-service-text119">
                About these Terms of Use
              </span>
              <br></br>
              <span>
                Please carefully read these terms of use (“Terms of Use”) and
                Privacy Statement before using our or our affiliates’ websites
                and any services made available by us or our affiliates via our
                website(s) (collectively, the “Site”) . These Terms of Use
                govern your use of the Site. By accessing, using, downloading,
                or logging into the Site, you agree to these Terms of Use and
                you represent that you are of legal age to agree to these Terms
                of Use, and the Privacy Statement.
              </span>
              <br className="terms-of-service-text122"></br>
              <br></br>
              <span className="terms-of-service-text124">
                Changes to these Terms of Use
              </span>
              <br></br>
              <span>
                We may change these Terms of Use at any time in our sole
                discretion without prior notice. Any changes to these Terms of
                Use will be in effect as of the “Last Updated” date referenced
                above. If you do not agree to the changes, you shall immediately
                stop using or logging into the Site.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text127"></br>
              <br></br>
              <span className="terms-of-service-text129">
                Relationship between these Terms of Use and our separate
                agreements with you
              </span>
              <br></br>
              <span>
                We and our affiliates offer a wide variety of products and
                services that require additional terms and conditions. Your use
                of those products and services provided by us or our affiliates
                are pursuant to a separate agreement (“General Terms
                Agreement”). The General Terms Agreement forms part of your
                agreement with us or our affiliates (as applicable). If these
                Terms of Use are inconsistent with the General Terms Agreement
                with respect to those products and services, the General Terms
                Agreement will control.
              </span>
              <br className="terms-of-service-text132"></br>
              <br></br>
              <span className="terms-of-service-text134">
                Access and Use of the Site
              </span>
              <br></br>
              <span>
                When you access or use the Site, you agree to use the Site only
                for purposes that are permitted by these Terms of Use and any
                applicable law, regulation, or generally accepted practices or
                guidelines in relevant applicable jurisdictions. Also, you agree
                that you are solely responsible for any breach of your
                obligations under these Terms of Use and for the consequences of
                any such breach.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text137"></br>
              <br></br>
              <span>
                As a condition of your use of the Site, you warrant to us that
                you will not use the Site for any purpose that is unlawful or
                otherwise prohibited by these Terms of Use. Whether on behalf of
                yourself or on behalf of any third party, you agree not to:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text140"></br>
              <br></br>
              <span>
                - access or attempt to access or use the Site other than through
                the interface that is provided by us, or through user accounts
                of others, unless you have specifically been permitted to do so
                in a separate written agreement signed by our or our affiliates’
                authorised representative (as applicable);
              </span>
              <br></br>
              <span>
                - engage in any activity that disrupts or otherwise interferes
                with the Site (or the servers and networks which are connected
                to the Site);
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text145"></br>
              <span>
                - scrape, duplicate, reproduce, copy, republish, licence, sell,
                trade or resell the Site or any of its content for any purpose,
                unless you have specifically been permitted to do so in a
                separate written agreement signed by our or our affiliates’
                authorised representative;
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text147"></br>
              <span>
                - divert or attempt to divert our or our affiliates’ customers
                to another website, mobile application, or service;
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text149"></br>
              <span>
                - sell, share, or otherwise transfer your account username,
                password, other information, or your rights or obligations under
                these Terms of Use;
              </span>
              <br className="terms-of-service-text151"></br>
              <span>
                - use the Site to store or transmit infringing or otherwise
                unlawful material, or to store or transmit material in violation
                of third party rights;
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text153"></br>
              <span>
                - use the Site to store or transmit malicious code or malware of
                any kind, or to engage in phishing or other fraudulent activity;
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text155"></br>
              <span>
                - modify, adapt, translate, reverse engineer, probe, scan, test,
                decompile, or disassemble any portion of the Site; and
              </span>
              <br className="terms-of-service-text157"></br>
              <span>
                - access, download, use or export the Site in violation of any
                applicable export control laws, sanctions regimes, or similar
                regulations.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text159"></br>
              <br></br>
              <span className="terms-of-service-text161">Your Account</span>
              <br></br>
              <span>
                To access certain services, features, portions, or content on
                the Site, you may be required to create an account and specify a
                password. To create an account, you must be at least 18 years
                old and you must provide complete, and accurate information. Do
                not impersonate any person or entity in connection with the Site
                or your account. If your information changes at any time, it is
                your responsibility to update your account to reflect those
                changes.
              </span>
              <br className="terms-of-service-text164"></br>
              <br></br>
              <span>
                In some cases, an account may be assigned to you by an
                administrator, such as your employer. In such cases, additional
                terms may apply to your use of the Site, and the administrator
                of your account may be able to access or disable your account
                without our involvement.
              </span>
              <br className="terms-of-service-text167"></br>
              <br></br>
              <span>
                Except as expressly permitted under these Terms of Use, you may
                not share your account with anyone else. You are responsible for
                maintaining the confidentiality of and preventing the
                unauthorised use of your account information and for all
                activities that occur under your account. We reserve the right
                to suspend, disable, deactivate, or block your account at any
                time. Where you have entered into a General Terms Agreement, we
                will do so in accordance with the General Terms Agreement.
              </span>
              <br className="terms-of-service-text170"></br>
              <br></br>
              <span className="terms-of-service-text172">
                Intellectual Property
              </span>
              <br></br>
              <span>
                Except as may otherwise be provided, the Site and the content
                included in or made available through the Site, such as text,
                graphics, logos, images, sounds, videos, digital downloads, data
                compilation, software, and documents, are our exclusive property
                and/or that of our licensors and content providers, and are
                protected by international intellectual property laws. All
                rights, title and interest not expressly granted with respect to
                the Site and content provided on or made available through the
                Site are reserved and retained by us and our licensors and
                content providers.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text175"></br>
              <br></br>
              <span>
                If you believe that your intellectual property rights are being
                infringed on the Site, please immediately contact us at
                cs@dg-innotech.com
              </span>
              <br></br>
              <br></br>
              <span className="terms-of-service-text180">
                Your visits to the Site
              </span>
              <br></br>
              <span>
                Where we or our affiliates collect information about you on a
                Site, this is subject to the Privacy Statement.
              </span>
              <br className="terms-of-service-text183"></br>
              <br></br>
              <span className="terms-of-service-text185">
                External Links on Site
              </span>
              <br></br>
              <span>
                The Site may contain links to third party websites. That doesn’t
                mean we control or endorse those websites, or any goods or
                services sold on those websites. Similarly, the Site may contain
                advertisements from third parties. We do not control or endorse
                any products being advertised. USE OF THIRD PARTY SITES IS AT
                YOUR OWN RISK AND IS SUBJECT TO ANY TERMS, CONDITIONS, AND
                POLICIES APPLICABLE TO SUCH THIRD PARTY SITES AND, WHERE
                APPLICABLE, PURSUANT TO THE GENERAL TERMS AGREEMENT.
              </span>
              <br className="terms-of-service-text188"></br>
              <br></br>
              <span className="terms-of-service-text190">
                Disclaimer of Warranties
              </span>
              <br></br>
              <span>
                Your use and access to the Site may be subject to delays,
                limitations, and other problems inherent in the use of the
                internet and electronic communications. We are not responsible
                for any delays, failures, or other damage resulting from such
                problems.
              </span>
              <br className="terms-of-service-text193"></br>
              <br></br>
              <span>
                OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OF USE, TO THE
                GREATEST EXTENT PERMITTED UNDER APPLICABLE LAW, NEITHER WE NOR
                OUR AFFILIATES, AGENTS OR SERVICE PROVIDERS MAKE ANY SPECIFIC
                PROMISES ABOUT THE SITE. WE MAKE NO REPRESENTATIONS OR
                WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AS TO THE
                OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS,
                PROGRAMS, OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO
                YOU THROUGH THE SITE, UNLESS OTHERWISE SPECIFIED IN WRITING. THE
                SITE IS MADE AVAILABLE ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                TO THE GREATEST EXTENT PERMITTED UNDER APPLICABLE LAW, WE AND
                OUR AFFILIATES EXPRESSLY DISCLAIM ALL IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                NON-INFRINGEMENT WITH RESPECT TO THE SITE AND ALL INFORMATION,
                CONTENT, MATERIALS, PROGRAMS, OR SERVICES INCLUDED ON OR
                OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SITE.
              </span>
              <br className="terms-of-service-text196"></br>
              <br></br>
              <span className="terms-of-service-text198">
                Limitation of Liability for the Site
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                TO THE GREATEST EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO
                EVENT SHALL WE, OUR AFFILIATES, AGENTS, OR SERVICE PROVIDERS BE
                LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                CONSEQUENTIAL, OR EXEMPLARY DAMAGES, OR ANY DAMAGES OF ANY KIND
                ARISING FROM YOUR ACCESS OR USE OF THE SITE OR ANY THIRD PARTY’S
                USE OF THE SITE. IF YOU HAVE A SEPARATE GENERAL TERMS AGREEMENT
                WITH US, THE PROVISIONS OF THAT AGREEMENT MAY ALSO APPLY.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text202"></br>
              <br></br>
              <span className="terms-of-service-text204">
                Governing Law and Process for Disputes
              </span>
              <br></br>
              <span>
                These Terms of Use and any dispute under these Terms of Use or
                the Site shall be governed by the law of the Hong Kong, without
                regard to any conflict of laws rules. You expressly agree that
                the courts located in Hong Kong have exclusive jurisdiction for
                any claim or dispute under these Terms of Use and/or your use of
                the Site. To the greatest extent permitted under applicable law,
                you and we agree that neither party can bring a dispute as a
                plaintiff or member in a class action.
              </span>
              <br className="terms-of-service-text207"></br>
              <br></br>
              <span>
                If you want to notify us or our affiliates of any legal claims
                or actions under these Terms of Use, please notify us (i) via
                email at cs@dg-innotech.com; or (ii) via physical mail at Unit
                1701, 17/F, Pan Asia Centre, 
              </span>
              <span>
                137 Wai Yip Street, Kwun Tong, Hong Kong with a PDF copy to
                cs@dg-innotech.com.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text211"></br>
              <br></br>
              <span className="terms-of-service-text213">
                Additional Details
              </span>
              <br></br>
              <span>
                If you do not comply with these Terms of Use, and we do not take
                immediate action, that does not mean we or our affiliates are
                giving up any rights we may have to take action in the future.
              </span>
              <br className="terms-of-service-text216"></br>
              <br></br>
              <span>
                If it turns out that a particular provision in these Terms of
                Use is not enforceable or valid, that will not affect any other
                provision in these Terms of Use.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br className="terms-of-service-text219"></br>
              <br></br>
              <span>
                We reserve the right to modify the Site at any time without
                notice to you. For example, we may add or remove functionality
                or features to the Site, or we may suspend or stop a particular
                feature or the Site altogether. We also reserve the right to
                charge a fee for any of the Site’s features at any time. If you
                don’t like any changes, you can stop using the Site at any time.
              </span>
              <br className="terms-of-service-text222"></br>
              <br></br>
              <span className="terms-of-service-text224">Who we are</span>
              <br></br>
              <span>
                References to “we”, “us” and “our” means DG Innotech with
                registered business address at Unit 1701, 17/F, Pan Asia
                Centre, 137 Wai Yip Street, Kwun Tong, Hong Kong), and to its
                affiliates as applicable. All postal correspondence should be
                sent to Unit 1701, 17/F, Pan Asia Centre, 137 Wai Yip Street,
                Kwun Tong, Hong Kong, with a PDF copy to cs@dg-innotech.com.
              </span>
            </span>
          </Fragment>
        }
        content9={
          <Fragment>
            <span className="terms-of-service-text227">
              We and our affiliates offer a wide variety of products and
              services that require additional terms and conditions. Your use of
              those products and services provided by us or our affiliates are
              pursuant to a separate agreement (“General Terms Agreement”). The
              General Terms Agreement forms part of your agreement with us or
              our affiliates (as applicable). If these Terms of Use are
              inconsistent with the General Terms Agreement with respect to
              those products and services, the General Terms Agreement will
              control.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        heading7={
          <Fragment>
            <span className="terms-of-service-text228">Terms of service</span>
          </Fragment>
        }
      ></ContentList41>
      <Footer31
        text={
          <Fragment>
            <span className="terms-of-service-text229">
              <span>Campflyer</span>
              <br></br>
            </span>
          </Fragment>
        }
        link1={
          <Fragment>
            <span className="terms-of-service-text232">Powered by Decoder</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="terms-of-service-text233">
              <span>WhatsApp:</span>
              <br></br>
              <span> +852 9637 5432</span>
              <br></br>
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="terms-of-service-text238">
              <span>Main Office:</span>
              <br></br>
              <span>
                Unit 1701, 17/F, Pan Asia Centre,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>137 Wai Yip Street, Kwun Tong, Hong Kong</span>
              <br></br>
            </span>
          </Fragment>
        }
        link11={
          <Fragment>
            <span className="terms-of-service-text245">
              <span>
                Email:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>cs@dg-innotech.com</span>
            </span>
          </Fragment>
        }
      ></Footer31>
    </div>
  )
}

export default TermsOfService
