import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './testimonial8.css'

const Testimonial8 = (props) => {
  return (
    <div
      id="partners"
      className={`testimonial8-container1 thq-section-padding ${props.rootClassName} `}
    >
      <h1 className="testimonial8-text10">
        {props.heading ?? (
          <Fragment>
            <span className="testimonial8-text23">客戶評語</span>
          </Fragment>
        )}
      </h1>
      <div className="testimonial8-max-width thq-section-max-width">
        <div
          data-thq="slider"
          data-loop="true"
          data-autoplay="true"
          data-navigation="true"
          data-pagination="true"
          className="testimonial8-slider swiper"
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="testimonial8-slider-slide1 swiper-slide"
            >
              <div className="testimonial8-container2">
                <div className="testimonial8-content1">
                  <div className="testimonial8-stars1">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon10 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon12 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon14 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon16 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon18 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                  </div>
                  <p className="testimonial8-text11 thq-body-large">
                    {props.review1 ?? (
                      <Fragment>
                        <span className="testimonial8-text24">
                          Campflyer
                          改變了營銷方式，它強大的功能優化了顧客參與策略。創新的功能和用戶友好的介面為行業樹立新的標準。
                        </span>
                      </Fragment>
                    )}
                  </p>
                  <div className="testimonial8-avatar1">
                    <div className="testimonial8-avatar-content1">
                      <span className="testimonial8-text12 thq-body-small">
                        {props.author1Name ?? (
                          <Fragment>
                            <span className="testimonial8-text25">
                              SKECHERS
                            </span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <img
                  alt={props.image1Alt}
                  src={props.image1Src}
                  className="testimonial8-thumbnail1 thq-img-ratio-1-1"
                />
              </div>
            </div>
            <div
              data-thq="slider-slide"
              className="testimonial8-slider-slide2 swiper-slide"
            >
              <div className="testimonial8-container3">
                <div className="testimonial8-content2">
                  <div className="testimonial8-stars2">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon20 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon22 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon24 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon26 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon28 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                  </div>
                  <p className="testimonial8-text13 thq-body-large">
                    {props.review2 ?? (
                      <Fragment>
                        <span className="testimonial8-text17">
                          <span>
                            Campflyer
                            改變了奢侈品牌的營銷工作。用戶友好的平台允許用戶輕鬆建立具針對性的營銷活動，直接向精英客戶傳遞個人化訊息。
                            <span
                              dangerouslySetInnerHTML={{
                                __html: ' ',
                              }}
                            />
                          </span>
                          <br></br>
                        </span>
                      </Fragment>
                    )}
                  </p>
                  <div className="testimonial8-avatar2">
                    <div className="testimonial8-avatar-content2">
                      <span className="testimonial8-text14 thq-body-small">
                        {props.author2Position ?? (
                          <Fragment>
                            <span className="testimonial8-text20">PANERAI</span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <img
                  alt={props.image1Alt3}
                  src={props.image1Src3}
                  className="testimonial8-thumbnail2 thq-img-ratio-1-1"
                />
              </div>
            </div>
            <div
              data-thq="slider-slide"
              className="testimonial8-slider-slide3 swiper-slide"
            >
              <div className="testimonial8-container4">
                <div className="testimonial8-content3">
                  <div className="testimonial8-stars3">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon30 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon32 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon34 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon36 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="testimonial8-icon38 thq-icon-small"
                    >
                      <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
                    </svg>
                  </div>
                  <p className="testimonial8-text15 thq-body-large">
                    {props.review21 ?? (
                      <Fragment>
                        <span className="testimonial8-text22">
                          我們透過建基於 WhatsApp
                          上的客戶獲取方法，推出了成功的電子優惠券活動，帶來了大量的線上和線下流量。平台的個人化溝通功能大大提高了客戶參與度和保留率。
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                      </Fragment>
                    )}
                  </p>
                  <div className="testimonial8-avatar3">
                    <div className="testimonial8-avatar-content3">
                      <span className="testimonial8-text16 thq-body-small">
                        {props.author2Position1 ?? (
                          <Fragment>
                            <span className="testimonial8-text21">SASA</span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <img
                  alt={props.image1Alt32}
                  src={props.image1Src32}
                  className="testimonial8-thumbnail3 thq-img-ratio-1-1"
                />
              </div>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="testimonial8-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="testimonial8-slider-button-prev swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="testimonial8-slider-button-next swiper-button-next"
          ></div>
        </div>
      </div>
    </div>
  )
}

Testimonial8.defaultProps = {
  review2: undefined,
  author2Position: undefined,
  image1Alt32: 'Company Alt',
  image1Src32:
    '/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(8)-300h.png',
  image1Alt: 'Company Alt',
  image1Alt3: 'Company Alt',
  author2Position1: undefined,
  review21: undefined,
  heading: undefined,
  rootClassName: '',
  image1Src3:
    '/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(9)1-300h.png',
  review1: undefined,
  image1Src:
    '/copy%20of%20blue%20%26%20white%20minimalist%20employees%20recruiting%20strategies%20tips%20instagram%20carousel%20post%20(1080%20%C3%A3%C2%97%20810%20px)%20(390%20%C3%A3%C2%97%20292%20px)%20(instagram%20post)%20(10)1-200h.png',
  author1Name: undefined,
}

Testimonial8.propTypes = {
  review2: PropTypes.element,
  author2Position: PropTypes.element,
  image1Alt32: PropTypes.string,
  image1Src32: PropTypes.string,
  image1Alt: PropTypes.string,
  image1Alt3: PropTypes.string,
  author2Position1: PropTypes.element,
  review21: PropTypes.element,
  heading: PropTypes.element,
  rootClassName: PropTypes.string,
  image1Src3: PropTypes.string,
  review1: PropTypes.element,
  image1Src: PropTypes.string,
  author1Name: PropTypes.element,
}

export default Testimonial8
