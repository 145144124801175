import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features231.css'

const Features231 = (props) => {
  return (
    <div
      id="features"
      className={`features231-layout349 thq-section-padding ${props.rootClassName} `}
    >
      <div className="features231-max-width thq-section-max-width">
        <div className="features231-container1">
          <h2 className="features231-text10 thq-heading-2">
            {props.heading1 ?? (
              <Fragment>
                <span className="features231-text24">
                  Our All-in-One Solution
                </span>
              </Fragment>
            )}
          </h2>
        </div>
        <div className="thq-grid-4 thq-grid-3">
          <div className="features231-container3 thq-card">
            <svg
              width="50"
              height="50"
              viewBox="0 0 24 24"
              className="features231-icon10"
            >
              <path
                d="M15 4a8 8 0 0 1 8 8a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8m0 2a6 6 0 0 0-6 6a6 6 0 0 0 6 6a6 6 0 0 0 6-6a6 6 0 0 0-6-6m-1 2h1.5v3.78l2.33 2.33l-1.06 1.06L14 12.4zM2 18a1 1 0 0 1-1-1a1 1 0 0 1 1-1h3.83c.31.71.71 1.38 1.17 2zm1-5a1 1 0 0 1-1-1a1 1 0 0 1 1-1h2.05L5 12l.05 1zm1-5a1 1 0 0 1-1-1a1 1 0 0 1 1-1h3c-.46.62-.86 1.29-1.17 2z"
                fill="currentColor"
              ></path>
            </svg>
            <h2 className="features231-text11 thq-heading-2">
              {props.feature1Title ?? (
                <Fragment>
                  <span className="features231-text21">Feature 1</span>
                </Fragment>
              )}
            </h2>
            <span className="features231-text12 thq-body-small">
              {props.feature1Description ?? (
                <Fragment>
                  <span className="features231-text19">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features231-container4 thq-card">
            <svg width="50" height="50" viewBox="0 0 24 24">
              <g
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M13 20.693c-.905.628-2.36.292-2.675-1.01a1.724 1.724 0 0 0-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066-2.573c-.94-1.543.826-3.31 2.37-2.37c1 .608 2.296.07 2.572-1.065c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.492.362 1.716 2.219.674 3.03"></path>
                <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0-6 0m8 10l5-3l-5-3z"></path>
              </g>
            </svg>
            <h2 className="features231-text13 thq-heading-2">
              {props.feature2Title ?? (
                <Fragment>
                  <span className="features231-text22">Feature 2</span>
                </Fragment>
              )}
            </h2>
            <span className="features231-text14 thq-body-small">
              {props.feature2Description ?? (
                <Fragment>
                  <span className="features231-text20">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features231-container5 thq-card">
            <svg width="50" height="50" viewBox="0 0 24 24">
              <path
                d="M3 3h8v8H3zm10 0h8v8h-8zM3 13h8v8H3zm15 0h-2v3h-3v2h3v3h2v-3h3v-2h-3z"
                fill="currentColor"
              ></path>
            </svg>
            <h2 className="features231-text15 thq-heading-2">
              {props.feature3Title ?? (
                <Fragment>
                  <span className="features231-text26">Feature 3</span>
                </Fragment>
              )}
            </h2>
            <span className="features231-text16 thq-body-small">
              {props.feature3Description ?? (
                <Fragment>
                  <span className="features231-text23">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features231-container6 thq-card">
            <svg width="50" height="50" viewBox="0 0 24 24">
              <path
                d="M6 1h12v22H6zm2 2v3h2.5v2H8v3h4v2H8v3h2.5v2H8v3h8V3z"
                fill="currentColor"
              ></path>
            </svg>
            <h2 className="features231-text17 thq-heading-2">
              {props.feature4Title ?? (
                <Fragment>
                  <span className="features231-text27">Feature 4</span>
                </Fragment>
              )}
            </h2>
            <span className="features231-text18 thq-body-small">
              {props.feature4Description ?? (
                <Fragment>
                  <span className="features231-text25">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Features231.defaultProps = {
  feature1Description: undefined,
  feature2Description: undefined,
  feature1Title: undefined,
  feature2Title: undefined,
  feature3Description: undefined,
  heading1: undefined,
  feature4Description: undefined,
  feature3Title: undefined,
  rootClassName: '',
  feature4Title: undefined,
}

Features231.propTypes = {
  feature1Description: PropTypes.element,
  feature2Description: PropTypes.element,
  feature1Title: PropTypes.element,
  feature2Title: PropTypes.element,
  feature3Description: PropTypes.element,
  heading1: PropTypes.element,
  feature4Description: PropTypes.element,
  feature3Title: PropTypes.element,
  rootClassName: PropTypes.string,
  feature4Title: PropTypes.element,
}

export default Features231
