import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hero81.css'

const Hero81 = (props) => {
  return (
    <div
      className={`hero81-header26 thq-section-padding ${props.rootClassName} `}
    >
      <div
        id="video"
        className="hero81-max-width thq-flex-column thq-section-max-width"
      >
        <div className="hero81-column">
          <div className="hero81-content">
            <h1 className="hero81-text1 thq-heading-1">
              {props.heading1 ?? (
                <Fragment>
                  <span className="hero81-text8">
                    Provide customers with what they want
                  </span>
                </Fragment>
              )}
            </h1>
            <p className="hero81-text2 thq-body-large thq-body-small">
              {props.content1 ?? (
                <Fragment>
                  <span className="hero81-text4">
                    <span>
                      We put all your data and campaigns on one platform,
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      so you can know your customers and provide them with what
                      they want.
                    </span>
                  </span>
                </Fragment>
              )}
            </p>
            <div className="hero81-actions">
              <a
                href="https://doqqi8stylu.typeform.com/to/zDp1N8uh"
                className="hero81-action2 thq-button-animated thq-button-outline"
              >
                <span className="thq-body-small">
                  {props.action2 ?? (
                    <Fragment>
                      <span className="hero81-text7">Explore More</span>
                    </Fragment>
                  )}
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="hero81-container1">
          <div className="hero81-container2">
            <Script
              html={`<iframe class="murf-embed" width="560" height="315"  src="https://murf.ai/embeds/index.html?embedId=m0te18r8"  allowfullscreen title="Murf Embed Player" style="border: none;"></iframe>
  <script src="https://murf.ai/embeds/widget.js" ></script>`}
            ></Script>
          </div>
        </div>
      </div>
    </div>
  )
}

Hero81.defaultProps = {
  content1: undefined,
  action2: undefined,
  heading1: undefined,
  rootClassName: '',
}

Hero81.propTypes = {
  content1: PropTypes.element,
  action2: PropTypes.element,
  heading1: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Hero81
