import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar12.css'

const Navbar12 = (props) => {
  return (
    <header className={`navbar12-container1 ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="navbar12-navbar-interactive">
        <div className="navbar12-container2">
          <a href="#Home" className="navbar12-link7">
            <div className="navbar12-container3">
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                className="navbar12-image"
              />
            </div>
          </a>
        </div>
        <div data-thq="thq-navbar-nav" className="navbar12-desktop-menu">
          <nav className="navbar12-links1">
            <a
              href="https://campflyer.io/#feature"
              className="navbar12-link11 thq-body-small thq-link"
            >
              {props.link1 ?? (
                <Fragment>
                  <span className="navbar12-text14">Why Campflyer</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://campflyer.io/#solution"
              className="navbar12-link21 thq-body-small thq-link"
            >
              {props.link2 ?? (
                <Fragment>
                  <span className="navbar12-text15">Solutions</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://campflyer.io/#partners"
              className="navbar12-link31 thq-body-small thq-link"
            >
              {props.link3 ?? (
                <Fragment>
                  <span className="navbar12-text22">Partners</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://campflyer.io/#pricing"
              className="navbar12-link32 thq-body-small thq-link"
            >
              {props.link31 ?? (
                <Fragment>
                  <span className="navbar12-text21">Pricing</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://campflyer.io/#team"
              className="navbar12-link41 thq-body-small thq-link"
            >
              {props.link45}
            </a>
          </nav>
          <div className="navbar12-buttons1">
            <a href="https://campflyer.io/home_cn" className="navbar12-link8">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                className="navbar12-icon10"
              >
                <path
                  d="M18 19h6v2h-6zm0-4h12v2H18zm0-4h12v2H18zm-4 10v-2H9v-2H7v2H2v2h8.215a8.6 8.6 0 0 1-2.216 3.977A9.3 9.3 0 0 1 6.552 23H4.333a10.9 10.9 0 0 0 2.145 3.297A14.7 14.7 0 0 1 3 28.127L3.702 30a16.4 16.4 0 0 0 4.29-2.336A16.5 16.5 0 0 0 12.299 30L13 28.127A14.7 14.7 0 0 1 9.523 26.3a10.3 10.3 0 0 0 2.729-5.3zm-2.833-8h2.166L8.75 2H6.583L2 13h2.166L5 11h5.334zM5.833 9l1.834-4.4L9.5 9z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
            <a
              href="https://campflyer.decoder.hk/login"
              className="navbar12-action21 thq-button-animated thq-button-outline"
            >
              <span className="navbar12-text10">
                {props.text1 ?? (
                  <Fragment>
                    <span className="navbar12-text24">Login</span>
                  </Fragment>
                )}
              </span>
            </a>
            <a
              href="https://doqqi8stylu.typeform.com/to/zDp1N8uh"
              className="navbar12-action22 thq-button-animated thq-button-outline"
            >
              <span className="navbar12-text11">
                {props.action2 ?? (
                  <Fragment>
                    <span className="navbar12-text18">Get a demo</span>
                  </Fragment>
                )}
              </span>
            </a>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="navbar12-burger-menu">
          <svg viewBox="0 0 1024 1024" className="navbar12-icon12">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="navbar12-mobile-menu">
          <div className="navbar12-nav">
            <div className="navbar12-top">
              <Link to="/" className="navbar12-navlink">
                <img
                  alt={props.logoAlt}
                  src={props.logoSrc}
                  className="navbar12-logo"
                />
              </Link>
              <div data-thq="thq-close-menu" className="navbar12-close-menu">
                <svg viewBox="0 0 1024 1024" className="navbar12-icon14">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="navbar12-links2">
              <a
                href="https://campflyer.io/#feature"
                className="navbar12-link12 thq-body-small thq-link"
              >
                {props.link1 ?? (
                  <Fragment>
                    <span className="navbar12-text14">Why Campflyer</span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://campflyer.io/#solution"
                className="navbar12-link22 thq-body-small thq-link"
              >
                {props.link2 ?? (
                  <Fragment>
                    <span className="navbar12-text15">Solutions</span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://campflyer.io/#partners"
                className="navbar12-link33 thq-body-small thq-link"
              >
                {props.link3 ?? (
                  <Fragment>
                    <span className="navbar12-text22">Partners</span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://campflyer.io/#pricing"
                className="navbar12-link34 thq-body-small thq-link"
              >
                {props.link32 ?? (
                  <Fragment>
                    <span className="navbar12-text23">Pricing</span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://campflyer.io/#team"
                className="navbar12-link42 thq-body-small thq-link"
              >
                {props.link4 ?? (
                  <Fragment>
                    <span className="navbar12-text17">About us</span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://campflyer.io/terms"
                className="navbar12-link5 thq-body-small thq-link"
              >
                {props.link43 ?? (
                  <Fragment>
                    <span className="navbar12-text19">Terms</span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://campflyer.io/privacy"
                className="navbar12-link6 thq-body-small thq-link"
              >
                {props.link44 ?? (
                  <Fragment>
                    <span className="navbar12-text16">Privacy</span>
                  </Fragment>
                )}
              </a>
            </nav>
            <div className="navbar12-buttons2">
              <a
                href="https://campflyer.decoder.hk/login"
                className="navbar12-action23 thq-button-animated thq-button-outline"
              >
                <span className="navbar12-text12">
                  {props.text2 ?? (
                    <Fragment>
                      <span className="navbar12-text20">Login</span>
                    </Fragment>
                  )}
                </span>
              </a>
              <a
                href="https://doqqi8stylu.typeform.com/to/zDp1N8uh"
                className="navbar12-action24 thq-button-animated thq-button-outline"
              >
                <span className="navbar12-text13">
                  {props.action2 ?? (
                    <Fragment>
                      <span className="navbar12-text18">Get a demo</span>
                    </Fragment>
                  )}
                </span>
              </a>
              <a href="https://campflyer.io/home_cn" className="navbar12-link9">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  className="navbar12-icon16"
                >
                  <path
                    d="M18 19h6v2h-6zm0-4h12v2H18zm0-4h12v2H18zm-4 10v-2H9v-2H7v2H2v2h8.215a8.6 8.6 0 0 1-2.216 3.977A9.3 9.3 0 0 1 6.552 23H4.333a10.9 10.9 0 0 0 2.145 3.297A14.7 14.7 0 0 1 3 28.127L3.702 30a16.4 16.4 0 0 0 4.29-2.336A16.5 16.5 0 0 0 12.299 30L13 28.127A14.7 14.7 0 0 1 9.523 26.3a10.3 10.3 0 0 0 2.729-5.3zm-2.833-8h2.166L8.75 2H6.583L2 13h2.166L5 11h5.334zM5.833 9l1.834-4.4L9.5 9z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="navbar12-icon-group">
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="thq-icon-x-small"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="thq-icon-x-small"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="thq-icon-x-small"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
    </header>
  )
}

Navbar12.defaultProps = {
  link1: undefined,
  logoAlt: 'logo',
  link2: undefined,
  link44: undefined,
  link4: undefined,
  action2: undefined,
  link45: 'About us',
  link43: undefined,
  logoSrc: '/re%20(7)-1500h.png',
  rootClassName: '',
  imageSrc1: '/re%20(7)-1500h.png',
  imageAlt1: 'image',
  text2: undefined,
  link31: undefined,
  link3: undefined,
  link32: undefined,
  text1: undefined,
}

Navbar12.propTypes = {
  link1: PropTypes.element,
  logoAlt: PropTypes.string,
  link2: PropTypes.element,
  link44: PropTypes.element,
  link4: PropTypes.element,
  action2: PropTypes.element,
  link45: PropTypes.string,
  link43: PropTypes.element,
  logoSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt1: PropTypes.string,
  text2: PropTypes.element,
  link31: PropTypes.element,
  link3: PropTypes.element,
  link32: PropTypes.element,
  text1: PropTypes.element,
}

export default Navbar12
