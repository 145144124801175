import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features174.css'

const Features174 = (props) => {
  return (
    <main
      id="solutions"
      className={`features174-layout349 thq-section-padding ${props.rootClassName} `}
    >
      <div className="features174-max-width thq-section-max-width">
        <div className="features174-image-container">
          <img
            alt={props.feature1ImageAlt}
            src={props.feature1ImageSrc}
            className="thq-img-ratio-16-9"
          />
        </div>
        <div className="features174-content1">
          <div className="features174-section-title">
            <span className="features174-text10 thq-body-small">
              {props.feature1Slogan ?? (
                <Fragment>
                  <span className="features174-text22">
                    Unify any amount of data from anywhere to understand your
                    customers
                  </span>
                </Fragment>
              )}
            </span>
            <div className="features174-content2">
              <h2 className="features174-text11 thq-heading-2">
                {props.feature1Title ?? (
                  <Fragment>
                    <span className="features174-text13">Data Exploration</span>
                  </Fragment>
                )}
              </h2>
              <p className="features174-text12 thq-body-large">
                {props.feature1Description ?? (
                  <Fragment>
                    <span className="features174-text14">
                      <span>
                        - Direct connection to your database including customer
                        and brand data
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        - Build individual customer profiles for customer
                        segmentation
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        - Perform on-demand data queries with low code tools to
                        discover insights
                      </span>
                    </span>
                  </Fragment>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

Features174.defaultProps = {
  feature1Title: undefined,
  feature1Description: undefined,
  feature1ImageAlt: 'Image',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1442458017215-285b83f65851?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI2fHxvcmFuZ2V8ZW58MHx8fHwxNzI0NjY2NTgzfDA&ixlib=rb-4.0.3&w=1400',
  feature1Slogan: undefined,
  rootClassName: '',
}

Features174.propTypes = {
  feature1Title: PropTypes.element,
  feature1Description: PropTypes.element,
  feature1ImageAlt: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  feature1Slogan: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Features174
