import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features175.css'

const Features175 = (props) => {
  return (
    <div
      className={`features175-layout349 thq-section-padding ${props.rootClassName} `}
    >
      <div className="features175-max-width thq-section-max-width">
        <div className="features175-image-container">
          <img
            alt={props.feature1ImageAlt}
            src={props.feature1ImageSrc}
            className="thq-img-ratio-16-9"
          />
        </div>
        <div className="features175-content1">
          <div className="features175-section-title">
            <span className="features175-text10 thq-body-small">
              {props.feature1Slogan ?? (
                <Fragment>
                  <span className="features175-text13">
                    Fine-tune the customer journey and maximize results for your
                    brand
                  </span>
                </Fragment>
              )}
            </span>
            <div className="features175-content2">
              <h2 className="features175-text11 thq-heading-2">
                {props.feature1Title ?? (
                  <Fragment>
                    <span className="features175-text14">Optimization</span>
                  </Fragment>
                )}
              </h2>
              <p className="features175-text12 thq-body-large">
                {props.feature1Description ?? (
                  <Fragment>
                    <span className="features175-text15">
                      <span>
                        - Find the best strategies with A/B testing experiment
                        including creatives and channel mix
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        - Smart budget allocation to send more messages with
                        high performing channels and creatives
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        - Reach individual customers with the channel and
                        creatives they’re most likely to engage with based on
                        past interactions
                      </span>
                    </span>
                  </Fragment>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Features175.defaultProps = {
  feature1Slogan: undefined,
  rootClassName: '',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1607185864766-e79e0315bfbe?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDExOXx8b3JhbmdlfGVufDB8fHx8MTcyNDY2NzExOHww&ixlib=rb-4.0.3&w=1400',
  feature1Title: undefined,
  feature1ImageAlt: 'Image',
  feature1Description: undefined,
}

Features175.propTypes = {
  feature1Slogan: PropTypes.element,
  rootClassName: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  feature1Title: PropTypes.element,
  feature1ImageAlt: PropTypes.string,
  feature1Description: PropTypes.element,
}

export default Features175
